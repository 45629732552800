import React from "react";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import {
  Divider,
  Container,
  Button,
  Select,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import MainAppBar from "../../components/MainAppBar";
import { BarChart } from "../../components/BarChart";
import LoadingView from "../../components/loadingView";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import DialogWarning from "../../components/dialogWarning";
import DisableToursPopover from "../../../utils/disableToursPopover";
import FloatingVideoLink from "../../components/floatingVideoLinks";

const AccountView = (props) => {
  return (
    <>
      <MainAppBar
        headerText="Mon compte"
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <NoSubscriptionBanner
        role={props.state.account?.role}
        redirectToSelectedPack={props.state.account?.redirectToSelectedPack}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <FloatingVideoLink role={props.state.account?.role}></FloatingVideoLink>
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Container className={props.classes.formContainer} maxWidth="lg">
        <form
          className={props.classes.form}
          autoComplete="off"
          onSubmit={props.submitForm}
        >
          <TextField
            required
            name="firstName"
            id="filled-required"
            defaultValue={props.state.account.firstName}
            label="Prénom"
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "firstNameNotLoaded"
                : "firstNameLoaded"
            }
          />
          <TextField
            required
            name="lastName"
            id="filled-required"
            defaultValue={props.state.account.lastName}
            label="Nom"
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded ? "lastNameNotLoaded" : "lastNameLoaded"
            }
          />
          <br />
          <TextField
            disabled
            id="outlined-disabled"
            defaultValue={props.state.account.email}
            label="Email"
            variant="outlined"
            size="small"
            className={props.classes.textField}
            key={props.state.accountLoaded ? "emailNotLoaded" : "emailLoaded"}
          />
          <TextField
            required
            name="company"
            id="filled-required"
            defaultValue={props.state.account.company}
            label="Entreprise"
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 35 }}
            key={
              props.state.accountLoaded
                ? "companyNotLoaded"
                : "companyNameLoaded"
            }
          />
          <br />
          <Link
            className={props.classes.passwordForgotten}
            to="/request-password-reset"
          >
            Changer le mot de passe
          </Link>
          <br />
          <br />
          <Divider />
          <br />
          <TextField
            name="phoneNumber"
            defaultValue={props.state.account.phoneNumber}
            label="Téléphone"
            variant="outlined"
            size="small"
            type="tel"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "phoneNumberNotLoaded"
                : "phoneNumberLoaded"
            }
          />
          <TextField
            name="siret"
            defaultValue={props.state.account.siret}
            label="Siret"
            variant="outlined"
            size="small"
            type="number"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "siretNameNotLoaded"
                : "siretNameLoaded"
            }
          />
          <br />
          <TextField
            name="streetAddress"
            defaultValue={props.state.account.streetAddress}
            label="Rue"
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "streetAddressNotLoaded"
                : "streetAddressNameLoaded"
            }
          />
          <TextField
            name="city"
            defaultValue={props.state.account.city}
            label="Ville"
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded ? "cityNameNotLoaded" : "cityNameLoaded"
            }
          />
          <TextField
            name="zipCode"
            defaultValue={props.state.account.zipCode}
            label="Code postal"
            variant="outlined"
            size="small"
            type="number"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded ? "zipCodeNotLoaded" : "zipCodeLoaded"
            }
          />
          <br />
          <br />
          <Box style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Button
              variant="outlined"
              className={props.classes.saveButton}
              type="submit"
            >
              ENREGISTRER
            </Button>
          </Box>
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
          <br />
          <Divider />
          <br />

          <Grid>
            <Box item xs={6}>
              {props.state.account.role === "PACK_DECOUVERTE" ||
              props.state.account.role === "NO_SUBSCRIBTION" ? (
                <Typography className={props.classes.optionLocked}>
                  Leaderboard désactivé
                </Typography>
              ) : (
                <Typography className={props.classes.optionUnlocked}>
                  Leaderboard activé
                </Typography>
              )}
              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  Parcours maximum
                  <b>{props.state.account.maxNbOfTours}</b>
                </Typography>
              </Box>
              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  Zones maximum par parcours
                  <b>{props.state.account.maxZones}</b>
                </Typography>
              </Box>

              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  Énigmes maximum par zone
                  <b>{props.state.account.maxEnigmas}</b>
                </Typography>
              </Box>

              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  Type de compte
                  <b>
                    {props.state.account.role === "PACK_DECOUVERTE"
                      ? "Pack découverte "
                      : props.state.account.role === "PACK_AVENTURE"
                      ? "Pack aventure"
                      : props.state.account.role === "PACK_EXPERT"
                      ? "Pack expert"
                      : props.state.account.role === "NO_SUBSCRIBTION"
                      ? "Pas d'abonnement"
                      : props.state.account.role === "ADMIN"
                      ? "Administrateur"
                      : "Pas d'abonnement"}
                  </b>
                </Typography>
              </Box>
            </Box>

            <Box item xs={6}>
              <Link to={`/subscribe`}>
                {" "}
                <Button variant="contained" className={props.classes.updateAbo}>
                  {props.state.account.role === "NO_SUBSCRIBTION" ||
                  !props.state.account.stripeCustomerId
                    ? "Abonnement"
                    : "Changer d'abonnement"}
                </Button>
              </Link>

              {props.state.account.stripeCustomerId && (
                <Button
                  variant="contained"
                  className={props.classes.updateAbo}
                  onClick={props.getCustomerPortalSession}
                >
                  Informations facturation
                </Button>
              )}

              {props.state.account.role !== "NO_SUBSCRIBTION" &&
                props.state.account.stripeCustomerId && (
                  <Button
                    variant="contained"
                    className={props.classes.deleteAbo}
                    onClick={props.openDialog}
                  >
                    Résilier mon abonnement
                  </Button>
                )}
            </Box>
          </Grid>

          <DialogWarning
            open={props.state.dialogWaringOpened}
            primaryText="Voulez-vous vraiment mettre fin à votre abonnement ?"
            secondaryText="L'abonnement prendra fin à la fin de la période en cours."
            primaryAction={props.onCancelSubscription}
            closeAction={props.closeDialog}
            primaryButtonText="Valider"
            secondaryButtonText="Annuler"
          />

          <br />

          <Divider />
          <br />

          <Box className={props.classes.optionsContainer}>
            <Typography className={props.classes.accountOptionsText}>
              Code admin
              <b>{props.state.account.adminCode}</b>
            </Typography>
          </Box>
          <Box className={props.classes.optionsContainer}>
            <Button
              variant="outlined"
              className={props.classes.deactivateToursButton}
              disabled={props.isButtonDeactivatedToursDisabled()}
              onClick={() => {
                props.openDeactivatedTours(true);
              }}
            >
              Activer/Désactiver des parcours
            </Button>
            {props.state.account.setDisabledToursAt &&
              props.isButtonDeactivatedToursDisabled() && (
                <Typography className={props.classes.reactivateButtonText}>
                  Vous pourrez à nouveau changer les parcours actifs le{" "}
                  {props.getDateButtonToursDisabledReactivate()}.
                </Typography>
              )}
          </Box>

          {/* <TextField
            disabled
            id="outlined-disabled"
            defaultValue={props.state.account.adminCode}
            label="Code admin"
            variant="outlined"
            size="small"
            className={props.classes.textField}
            key={
              props.state.accountLoaded
                ? "adminCodeNotLoaded"
                : "adminCodeLoaded"
            }
          /> */}
          {/* <TextField
            disabled
            id="outlined-disabled"
            defaultValue={props.state.account.secretCode}
            label="Code secret"
            helperText="Il s'agit du code que vous devez rentrer dans vos IPads pour pouvoir récupérer vos parcours"
            variant="outlined"
            size="small"
            className={props.classes.textField}
            key={
              props.state.accountLoaded
                ? "secretCodeNotLoaded"
                : "secretCodeLoaded"
            }
          /> */}
          <br />
          <Divider />
          <br />
          <br />
          <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
            Statistiques
          </Typography>
          <Select
            labelId="select-month-offset"
            id="select-month-offset"
            value={props.state.monthOffset ?? 999}
            onChange={props.updateMonthOffset}
          >
            <MenuItem value={999}>Tout</MenuItem>
            <MenuItem value={3}>3 mois</MenuItem>
            <MenuItem value={6}>6 mois</MenuItem>
            <MenuItem value={12}>1 an</MenuItem>
          </Select>
          {props.state.isAnalyticDatasReady ? (
            <BarChart chartData={props.state.analytics} />
          ) : (
            <>
              <br />
              <br />
              <CircularProgress />
            </>
          )}
        </form>
      </Container>
    </>
  );
};

export default AccountView;
