import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ApiTour from "../../../../../../API/ApiTour";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ApiUser from "../../../../../../API/ApiUser";

export const NewCodeDialog = ({ open, handleClose, tourId }) => {
  const [form, setForm] = useState({
    isAdmin: false,
    lastName: "",
    firstName: "",
    teamName: "",
    email: "",
    phoneNumber: "",
    usageLimitHours: "",
    maxNumberOfUsages: "",
    isTest: false,
    unlimitedUsages: false,
    isStoreGame: false,
    androidProductId: null,
    iosProductId: null,
    tourLanguage: null,
  });

  useEffect(() => {
    console.log("Mounted");
    setStateIsAdmin();
    return () => {
      console.log("Unmounted");
    };
  }, []);

  const handleChange = (e) => {
    const { name, type } = e.target;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ApiTour.newGuestUserTourCode({ ...form, tourId });
      setForm({
        lastName: "",
        firstName: "",
        teamName: "",
        email: "",
        phoneNumber: "",
        usageLimitHours: "",
        maxNumberOfUsages: "",
        isTest: false,
        unlimitedUsages: false,
        isStoreGame: false,
        androidProductId: null,
        iosProductId: null,
        tourLanguage: null,
      });
      handleClose();
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
    }
  };

  const setStateIsAdmin = async () => {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      setForm({
        isAdmin: response.data.isAdmin,
        lastName: "",
        firstName: "",
        teamName: "",
        email: "",
        phoneNumber: "",
        usageLimitHours: "",
        maxNumberOfUsages: "",
        isTest: false,
        unlimitedUsages: false,
        isStoreGame: false,
        androidProductId: null,
        iosProductId: null,
        tourLanguage: null,
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Nouveau code de jeu</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            name="firstName"
            label="Prénom"
            type="text"
            fullWidth
            required
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            name="lastName"
            label="Nom"
            type="text"
            fullWidth
            required
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            name="teamName"
            label="Nom d'équipe"
            type="text"
            fullWidth
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="phoneNumber"
            label="Téléphone"
            type="tel"
            fullWidth
            onChange={handleChange}
          />
          <Accordion style={{ marginTop: "20px", marginBottom: "20px" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Plus d'options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="maxNumberOfUsages"
                    label="Nombre d'utilisations max"
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 9999,
                    }}
                    fullWidth
                    onChange={handleChange}
                  />
                  <br></br>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="usageLimitHours"
                    label="Temps avant désactivation"
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 9999,
                    }}
                    fullWidth
                    onChange={handleChange}
                  />
                  <Typography
                    style={{
                      marginTop: 6,
                      paddingLeft: 8,
                      color: "#494949ff",
                      fontSize: 12,
                    }}
                    align="inherit"
                    gutterBottom
                  >
                    Temps avant que le code éxpire après le premier lancement
                    par un joueur (en heures)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="unlimitedUsages"
                        checked={form.unlimitedUsages}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Nombre d'utilisations illimitées"
                  />
                  <Typography
                    style={{
                      marginTop: 6,
                      paddingLeft: 8,
                      color: "#494949ff",
                      fontSize: 12,
                    }}
                    align="inherit"
                    gutterBottom
                  >
                    Le code pourra être utilisé par un nombre de joueurs
                    illimitées et sans limite de temps
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isTest"
                        checked={form.isTest}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Code de test"
                  />
                  {form.isTest ? (
                    <Typography
                      style={{
                        marginTop: 6,
                        paddingLeft: 8,
                        color: "#CA4E4E",
                        fontSize: 12,
                      }}
                      align="inherit"
                      gutterBottom
                    >
                      Attention, un bandeau "Parcours de test" sera affiché en
                      jeu.
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography
                    style={{
                      marginTop: 6,
                      paddingLeft: 8,
                      color: "#494949ff",
                      fontSize: 12,
                    }}
                    align="inherit"
                    gutterBottom
                  >
                    Le code généré servira uniquement à tester le parcours.
                    L'utilisation de ce code ne sera pas comptabilisé dans les
                    statistiques et dans le leaderboard.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>

            <div>
              {form.isAdmin ? (
                <>
                  <FormControlLabel
                    style={{ marginLeft: 6 }}
                    control={
                      <Checkbox
                        name="isStoreGame"
                        checked={form.isStoreGame}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Parcours disponible sur le store"
                  />
                  {form.isStoreGame ? (
                    <div style={{ marginLeft: 12 }}>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            name="androidProductId"
                            label="Android product ID"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                          />
                        </Grid>
                        <Typography
                          style={{
                            marginTop: 6,
                            paddingLeft: 8,
                            color: "#494949ff",
                            fontSize: 12,
                          }}
                          align="inherit"
                          gutterBottom
                        >
                          Laisser vide si gratuit
                        </Typography>

                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            name="iosProductId"
                            label="IOS product ID"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                          />
                        </Grid>

                        <Typography
                          style={{
                            marginTop: 6,
                            paddingLeft: 8,
                            color: "#494949ff",
                            fontSize: 12,
                          }}
                          align="inherit"
                          gutterBottom
                        >
                          Laisser vide si gratuit
                        </Typography>

                        <Grid item xs={12}>
                          <Select
                            name="tourLanguage"
                            id="select-language"
                            defaultValue="fr"
                            onChange={handleChange}
                          >
                            <MenuItem value="fr">Français</MenuItem>
                            <MenuItem value="en">Anglais</MenuItem>
                            <MenuItem value="al">Allemands</MenuItem>
                            <MenuItem value="es">Espagnol</MenuItem>
                            <MenuItem value="it">Italien</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </Accordion>

          <DialogActions>
            <Button onClick={handleClose} style={{ color: "#000000" }}>
              Annuler
            </Button>
            <Button type="submit" style={{ color: "#F48A21", fontWeight: 600 }}>
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
