import React from "react";
import {
  AppBar,
  Box,
  Card,
  Typography,
  Grid,
  Checkbox,
  Button,
  Slider,
  Switch,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import {
  CheckCircle,
  CheckCircleOutline,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import MainAppBar from "../../components/MainAppBar";
import DialogWarning from "../../components/dialogWarning";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import FloatingVideoLink from "../../components/floatingVideoLinks";

const SubscriptionView = (props) => {
  const {
    showLoadingView,
    role,
    isMonthly,
    classes,
    subscriptions,
    subscriptionsMonth,
    handleCheckboxChange,
    handleSubscriptionSelect,
  } = props;
  const subscriptionEntries = Object.entries(subscriptions);
  console.log("subscriptionEntries ", subscriptionEntries);

  return (
    <>
      <MainAppBar headerText="Parcours" showHomeButton={true} />
      <Box className={classes.container}>
        <Typography
          className={classes.title}
          style={{ fontWeight: "bold", fontSize: "32px" }}
        >
          Choisissez votre abonnement
        </Typography>
        <FloatingVideoLink role={role}></FloatingVideoLink>
        <Box display="flex" justifyContent="center" marginBottom={2}>
          <Typography style={{ fontWeight: "bold" }}>Mensuel</Typography>
          <Switch
            checked={!isMonthly}
            onChange={handleCheckboxChange}
            // color="primary"
            inputProps={{ "aria-label": "Abonnement mensuel ou 6 mois" }}
          />

          <Typography style={{ fontWeight: "bold" }}>6 mois</Typography>
          <Typography className={classes.badge}>-25%</Typography>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {showLoadingView ? (
            // Affiche un loader si subscriptionEntries est null
            <CircularProgress />
          ) : (
            subscriptionEntries
              .filter(([key, sub]) => sub)
              .map(([key, sub], index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    style={{ maxWidth: "350px" }}
                  >
                    <Card
                      className={`${classes.subscriptionCard} ${
                        sub.priceId === props.priceId ? "selectedPack" : ""
                      }`}
                    >
                      <Box className={classes.columnHeader}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          {sub.displayName}
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          {sub.month ? "Sans engagement" : ""}
                        </Typography>
                      </Box>
                      <Box className={classes.rowsContainer}>
                        {!isMonthly ? (
                          <Box className={classes.containerPricesDiff}>
                            <Typography className={classes.priceCrossed}>
                              {sub.priceId === "price_1Px5LfCPk14nMN0Xxo32l7cV"
                                ? `${subscriptionsMonth.decouverte.price * 6}€`
                                : sub.priceId ===
                                  "price_1Px5LkCPk14nMN0Xphhsmw5e"
                                ? `${subscriptionsMonth.aventure.price * 6}€`
                                : `${subscriptionsMonth.expert.price * 6}€`}
                            </Typography>
                            <Typography className={classes.price}>
                              {sub.price}€
                            </Typography>
                          </Box>
                        ) : (
                          <Typography className={classes.price}>
                            {sub.price}€
                          </Typography>
                        )}

                        {isMonthly ? (
                          <Typography className={classes.periodic}>
                            Mensuellement
                          </Typography>
                        ) : (
                          <Typography className={classes.periodic}>
                            Pour 6 mois
                          </Typography>
                        )}

                        <Typography
                          style={{ marginTop: 20 }}
                          className={classes.row}
                        >
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          Studio de création
                        </Typography>

                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxTours} parcours
                        </Typography>
                        <Divider className={classes.rowDivider} />
                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxZones} zones
                        </Typography>
                        <Divider className={classes.rowDivider} />
                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxEnigmas} énigmes par zone
                        </Typography>
                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          Aide en ligne
                        </Typography>
                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          {sub.hasLeaderboard ? (
                            <CheckCircleOutline style={{ marginRight: 6 }} />
                          ) : (
                            <RadioButtonUnchecked
                              style={{ color: "orange", marginRight: 6 }}
                            />
                          )}{" "}
                          Leaderboard
                        </Typography>
                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          {sub.tourOffered ? (
                            <CheckCircleOutline style={{ marginRight: 6 }} />
                          ) : (
                            <RadioButtonUnchecked
                              style={{ color: "orange", marginRight: 6 }}
                            />
                          )}{" "}
                          1 parcours interieur offert
                        </Typography>

                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          {sub.trainingOffered ? (
                            <CheckCircleOutline style={{ marginRight: 6 }} />
                          ) : (
                            <RadioButtonUnchecked
                              style={{ color: "orange", marginRight: 6 }}
                            />
                          )}{" "}
                          Formation 1h
                        </Typography>
                      </Box>

                      <Typography className={classes.ht} variant="body2">
                        Prix HT
                      </Typography>

                      {sub.priceId === props.priceId ? (
                        <Button
                          className={classes.subscribeButton}
                          style={{
                            backgroundColor: "grey",
                            cursor: "default",
                          }}
                          variant="contained"
                          color="primary"
                          onClick={() => {}}
                        >
                          Souscrire
                        </Button>
                      ) : (
                        <Button
                          className={classes.subscribeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (props.priceId) {
                              // Un abonnement est en cours
                              props.openDialog(key);
                            } else {
                              // Aucun abonnement en cours
                              handleSubscriptionSelect(key);
                            }
                          }}
                        >
                          Souscrire
                        </Button>
                      )}

                      {props.hasTrial && !props.priceId ? (
                        <Typography
                          style={{ marginTop: 20 }}
                          className={classes.weekFreeText}
                        >
                          🎁 Une semaine offerte
                        </Typography>
                      ) : (
                        <div style={{ height: 20 }}></div>
                      )}
                    </Card>
                  </Grid>
                  <DialogWarning
                    open={props.dialogWaringOpened}
                    primaryText="Voulez-vous vraiment changer d'abonnement ?"
                    secondaryText="Changement d'abonnement et calcul au prorata : Lorsque vous changez d'abonnement, que ce soit pour une mise à niveau vers un forfait supérieur ou un retour à un forfait inférieur, un ajustement au prorata sera effectué. Par exemple, si vous passez d'un forfait à 10€ par mois à un forfait à 20€ par mois, un montant calculé au prorata en fonction du temps passé sur chaque forfait vous sera facturé.
                    \n\n
                    Le changement prendra effet immédiatement."
                    primaryAction={() => props.handleSubscriptionSelect(null)}
                    closeAction={props.closeDialog}
                    primaryButtonText="Valider"
                    secondaryButtonText="Annuler"
                  />
                </>
              ))
          )}
        </Grid>
      </Box>
    </>
  );
};

export default SubscriptionView;
