import React, { useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";
import ApiUser from "../../../API/ApiUser";

import ApiEnigma from "../../../API/ApiEnigma";
import { getSizeInMb } from "../../../utils/sizeInMb";
import styles from "./newEnigmaStyles";
import NewEnigmaView from "./newEnigmaView";
import ImageUtils from "../../../utils/imageUtils";
import ApiZone from "../../../API/ApiZone";
import { EnigmaType } from "../../../constants/TourConstants";
import ApiTour from "../../../API/ApiTour";

class NewEnigma extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endOfPageRef: React.createRef(),
      isAdmin: false,
      role: null,
      tourDisabled: null,
      enigmaLoaded: false,
      editing: false,
      unsavedChanges: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      dialogWaringOpened: false,
      loadingViewOpened: false,
      showVintageImageTooBig: false,
      showArRecognitionImageTooBig: false,
      showClueImageTooBig: false,
      tourIdToDelete: -1,
      mapDialogOpen: false,
      showLatOnMap: null,
      showLngOnMap: null,
      currenZone: null,
      zoneLng: null,
      zoneLat: null,
      zoneRadius: null,
      formDatas: {
        tourId: null,
        zoneId: null,
        zoneMode: null,
        enigmaId: -1,
        type: EnigmaType.TEXT,
        responseType: "text",
        responsesPicker: [""],
        responsesTextStr: "",
        responsesPickerStr: "",
        compass: 0,
        switchLabels: [""],
        switchValues: [false],
        switchLen: 0,
        potentiometerLabels: [""],
        potentiometerValues: [0],
        potentiometerLen: 0,
        points: 25,
        pickerChoices: [],
        picker: null,
        title: "",
        finishText: "",
        youtubeVideoUrl: null,
        showFinishTextOnLoose: true,
        clue: {
          title: "",
          timer: 2,
          imageFile: null,
          imageUrl: null,
          imageDeleted: false,
        },
        vintage: {
          title: "",
          imageFile: null,
          imageUrl: null,
          imageDeleted: false,
        },
        arImageRecognition: {
          title: "",
          imageFile: null,
          imageUrl: null,
          imageDeleted: false,
        },
        arObject: {
          file: null,
          fileUrl: null,
          arObjectDeleted: false,
          arImageRecognitionDeleted: false,
        },
        coordsLongitudeArObject: 0,
        coordsLatitudeArObject: 0,
      },
    };

    this.submitForm = this.submitForm.bind(this);

    this.vintageImageSelected = this.vintageImageSelected.bind(this);
    this.clueImageSelected = this.clueImageSelected.bind(this);
    this.handleSelectResponseTypeChange =
      this.handleSelectResponseTypeChange.bind(this);
    this.handleResponsesPickerTextChange =
      this.handleResponsesPickerTextChange.bind(this);
    this.handleResponsesCompassChange =
      this.handleResponsesCompassChange.bind(this);
    this.handleSwitchLabelChange = this.handleSwitchLabelChange.bind(this);
    this.handleSwitchValueChange = this.handleSwitchValueChange.bind(this);
    this.addSwitch = this.addSwitch.bind(this);
    this.removeSwitch = this.removeSwitch.bind(this);
    this.handlePotentiometerLabelChange =
      this.handlePotentiometerLabelChange.bind(this);
    this.handlePotentiometerValueChange =
      this.handlePotentiometerValueChange.bind(this);
    this.addPotentiometer = this.addPotentiometer.bind(this);
    this.removePotentiometer = this.removePotentiometer.bind(this);
    this.updatePoints = this.updatePoints.bind(this);
    this.getPickerChoiceValue = this.getPickerChoiceValue.bind(this);
    this.updatePickerChoice = this.updatePickerChoice.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.updateResponsesText = this.updateResponsesText.bind(this);
    this.updateYoutubeVideoUrl = this.updateYoutubeVideoUrl.bind(this);
    this.updateClueTitle = this.updateClueTitle.bind(this);
    this.updateClueTime = this.updateClueTime.bind(this);
    this.updateVintageTitle = this.updateVintageTitle.bind(this);
    this.getPickerChoiceValue = this.getPickerChoiceValue.bind(this);
    this.updateFinishText = this.updateFinishText.bind(this);
    this.updateShowFinishOnLoose = this.updateShowFinishOnLoose.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.openMapDialog = this.openMapDialog.bind(this);
    this.closeMapDialog = this.closeMapDialog.bind(this);
    this.setArObjectLocation = this.setArObjectLocation.bind(this);
    this.deleteObjFile = this.deleteObjFile.bind(this);
    this.arObjectSelected = this.arObjectSelected.bind(this);
    this.updateArCoordsLongitude = this.updateArCoordsLongitude.bind(this);
    this.updateArCoordsLatitude = this.updateArCoordsLatitude.bind(this);
    this.hasOtherMediaThanYT = this.hasOtherMediaThanYT.bind(this);
    this.arImageRecognitionSelected =
      this.arImageRecognitionSelected.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    document.title = "Énigme | Landing Zone";

    this.getRole();

    let tourId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).tourId;
    let zoneId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).zoneId;
    let enigmaId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).enigmaId;

    let tourIdInt = parseInt(tourId);
    let zoneIdInt = parseInt(zoneId);
    let enigmaIdInt = parseInt(enigmaId);

    this.getTourDisableState(tourIdInt);

    if (!tourIdInt || !zoneIdInt) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage:
          "Une erreur est survenue. Impossible de trouver les données liées à cette zone",
        showSuccess: false,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      formDatas: {
        ...prevState.formDatas,
        tourId: tourIdInt,
        zoneId: zoneIdInt,
      },
    }));

    console.log("zoneIdInt ", zoneIdInt);
    if (!zoneIdInt) return;
    console.log("a");
    ApiZone.getZone(tourIdInt, zoneIdInt).then((zoneDatas) => {
      if (!zoneDatas || zoneDatas.status !== 200) {
        return;
      }
      console.log("zone datas : ", zoneDatas);
      this.setState((prevState) => ({
        ...prevState,
        currentZone: zoneDatas,
        zoneRadius: zoneDatas.data.zoneRadius,
        zoneLat: zoneDatas.data.coordsLatitude,
        zoneLng: zoneDatas.data.coordsLongitude,
        formDatas: {
          ...prevState.formDatas,
          zoneMode: zoneDatas.data.zoneMode,
        },
      }));
    });

    if (!enigmaIdInt) return;

    this.setStateIsAdmin();

    ApiEnigma.getEnigma(enigmaId).then((enigmaDatas) => {
      if (!enigmaDatas || enigmaDatas.status !== 200) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage:
            "Une erreur est survenue lors de la récupération du circuit",
          showSuccess: false,
        }));

        return;
      }

      this.setForm(enigmaDatas.data);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  async getRole() {
    let response = await ApiUser.getAccount();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        role: response.data.role,
      }));
    }
  }

  async getTourDisableState(tourId) {
    let response = await ApiTour.getTour(tourId);

    if (response.status == 200) {
      console.log("tour disabled ? ", response.data.disabled);
      this.setState((prevState) => ({
        ...prevState,
        tourDisabled: response.data.disabled,
      }));
    }
  }

  handleBeforeUnload = (event) => {
    if (this.state.unsavedChanges) {
      const message =
        "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?";
      event.returnValue = message;
      return message;
    }
  };

  updateArCoordsLongitude(event) {
    event.preventDefault();

    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.youtubeVideoUrl
    ) {
      console.log("a");
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        coordsLongitudeArObject: value,
      },
    }));
  }

  updateArCoordsLatitude(event) {
    event.preventDefault();

    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.youtubeVideoUrl
    ) {
      console.log("a");
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        coordsLatitudeArObject: value,
      },
    }));
  }

  async setArObjectLocation(location) {
    if (!location || !location.lat || !location.lng) return;

    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.youtubeVideoUrl
    ) {
      console.log("a");
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        coordsLongitudeArObject: location.lng,
        coordsLatitudeArObject: location.lat,
      },
    }));
  }

  closeMapDialog() {
    this.setState((prevState) => ({
      ...prevState,
      mapDialogOpen: false,
    }));
  }

  openMapDialog() {
    let lat = this.state.formDatas.coordsLatitudeArObject;
    let lng = this.state.formDatas.coordsLongitudeArObject;

    this.setState(
      (prevState) => ({
        ...prevState,

        mapDialogOpen: true,
        showLatOnMap: lat,
        showLngOnMap: lng,
      }),
      () => {}
    );
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  setForm(enigmaDatas) {
    let pickerChoices = [];

    for (let choice of enigmaDatas.picker) {
      pickerChoices.push(JSON.parse(choice));
    }

    this.setState((prevState) => ({
      ...prevState,
      editing: true,
      enigmaLoaded: true,
      formDatas: {
        ...prevState.formDatas,
        enigmaId: enigmaDatas.id,
        zoneId: enigmaDatas.ZoneId,
        responseType: enigmaDatas.responseType,
        points: enigmaDatas.points,
        responsesPicker:
          enigmaDatas.responseType == "picker"
            ? enigmaDatas.responses.split(";")
            : [""],
        responsesTextStr:
          enigmaDatas.responseType == "text" ? enigmaDatas.responses : "",
        responsesPickerStr:
          enigmaDatas.responseType == "picker" ? enigmaDatas.responses : "",
        compass:
          enigmaDatas.responseType == "compass" ? enigmaDatas.compass : null,
        switchLabels:
          enigmaDatas.responseType == "switch"
            ? enigmaDatas.switchLabels
              ? enigmaDatas.switchLabels
              : [""]
            : [""],
        switchValues:
          enigmaDatas.responseType == "switch"
            ? enigmaDatas.switchValues
              ? enigmaDatas.switchValues
              : [false]
            : [false],

        potentiometerLabels:
          enigmaDatas.responseType == "potentiometer"
            ? enigmaDatas.potentiometerLabels
              ? enigmaDatas.potentiometerLabels
              : [""]
            : [""],
        potentiometerValues:
          enigmaDatas.responseType == "potentiometer"
            ? enigmaDatas.potentiometerValues
              ? enigmaDatas.potentiometerValues
              : [0]
            : [0],

        type: enigmaDatas.type,
        pickerChoices: pickerChoices,
        picker: null,
        title: enigmaDatas.title,
        finishText: enigmaDatas.finishText,
        showFinishTextOnLoose: enigmaDatas.showFinishTextOnLoose,
        youtubeVideoUrl: enigmaDatas.youtubeVideoUrl,
        coordsLongitudeArObject: enigmaDatas.coordsLongitudeArObject,
        coordsLatitudeArObject: enigmaDatas.coordsLatitudeArObject,
        clue: {
          title: enigmaDatas.clueTitle,
          timer: enigmaDatas.clueTimer,
          imageFile: null,
          imageUrl: enigmaDatas.clueImage?.signedUrl,
        },
        vintage: {
          title: enigmaDatas.vintageTitle,
          imageFile: null,
          imageUrl: enigmaDatas.vintageImage?.signedUrl,
        },
        arObject: {
          file: null,
          fileUrl: enigmaDatas.arObject?.signedUrl,
          fileName: enigmaDatas.arObject?.name,
        },
        arImageRecognition: {
          file: null,
          imageUrl: enigmaDatas.arImageRecognition?.signedUrl,
          fileName: enigmaDatas.arImageRecognition?.name,
        },
      },
    }));
    console.log(
      "enigmaDatas.arImageRecognition ",
      enigmaDatas.arImageRecognition
    );
  }

  getPickerChoiceValue(event, i) {
    event.preventDefault();

    if (this.state.formDatas.pickerChoices.lenght > i)
      return this.state.formDatas.pickerChoices[i].value;

    return "";
  }

  updateFinishText(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        finishText: event.target.value,
      },
    }));
  }

  hasOtherMediaThanYT() {
    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.coordsLongitudeArObject ||
      this.state.formDatas.coordsLatitudeArObject ||
      this.state.formDatas.arImageRecognition.imageFile ||
      this.state.formDatas.arImageRecognition.imageUrl ||
      this.state.formDatas.arObject.file ||
      this.state.formDatas.arObject.fileUrl
    ) {
      return true;
    }
    return false;
  }

  updateYoutubeVideoUrl(event) {
    event.preventDefault();

    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.coordsLongitudeArObject ||
      this.state.formDatas.coordsLatitudeArObject ||
      this.state.formDatas.arImageRecognition.imageFile ||
      this.state.formDatas.arImageRecognition.imageUrl ||
      this.state.formDatas.arObject.file ||
      this.state.formDatas.arObject.fileUrl
    ) {
      console.log("a");
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
        formDatas: {
          ...prevState.formDatas,
          youtubeVideoUrl: null,
        },
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    console.log("b");

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        youtubeVideoUrl: event.target.value,
        showError: false,
      },
    }));
  }

  updateShowFinishOnLoose(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        showFinishTextOnLoose: event.target.value,
      },
    }));
  }

  updatePoints(event, value) {
    event.preventDefault();
    console.log("new points : ", parseInt(event.target.value));

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        points: parseInt(event.target.value),
      },
    }));
  }

  updateVintageTitle(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        vintage: {
          ...prevState.formDatas.vintage,
          title: event.target.value,
        },
      },
    }));
  }

  updateTitle(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        title: event.target.value,
      },
    }));
  }

  updateClueTitle(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        clue: {
          ...prevState.formDatas.clue,
          title: event.target.value,
        },
      },
    }));
  }

  updateClueTime(event, value) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        clue: {
          ...prevState.formDatas.clue,
          timer: parseInt(value),
        },
      },
    }));
  }

  updatePickerChoice(event, index) {
    event.preventDefault();

    var choices = this.state.formDatas.pickerChoices;
    var choiceExist = false;

    if (this.state.formDatas.pickerChoices) {
      this.state.formDatas.pickerChoices.forEach((choice, i) => {
        if (choice.id == event.target.id) {
          choices[i].value = event.target.value;
          choiceExist = true;
        }
      });
    } else {
      choices = [{ id: event.target.id, value: event.target.value }];
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        pickerChoices: choices,
      },
    }));
  }

  handleResponsesPickerTextChange(event) {
    if (event.target == null) {
      return;
    }

    // Parse les réponses (colonnes)
    let responsesTab = event.target.value.split(";");

    // Si pas de réponses, on return
    if (responsesTab == null) return;

    // Récupère les choix existants
    let currentChoices = this.state.formDatas.pickerChoices || [];
    let responses = [];
    let newChoices = [];

    // Ajoute les réponses de chaque colonne dans un tableau
    // Pour chaque réponse, ajoute une liste de choix (string avec ;) initialisée à ""
    for (let [index, elem] of responsesTab.entries()) {
      let pickerId = `picker-response-${index.toString()}`;
      responses.push(elem);

      // Cherche un choix existant pour cet index, sinon en crée un nouveau
      let existingChoice = currentChoices.find(
        (choice) => choice.id === pickerId
      );
      newChoices.push(existingChoice || { id: pickerId, value: "" });
    }

    // On ajoute dans le state les réponses et les choix mis à jour
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        responsesPicker: responses,
        responsesPickerStr: event.target.value,
        pickerChoices: newChoices,
      },
    }));
  }

  updateResponsesText(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        responsesTextStr: event.target.value,
      },
    }));
  }

  handleResponsesCompassChange(event) {
    event.preventDefault();

    if (event.target.value >= 0 && event.target.value <= 360) {
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          compass: event.target.value,
        },
      }));
    }
  }

  handleSwitchLabelChange(event, i) {
    event.preventDefault();
    const newSwitchLabels = [...this.state.formDatas.switchLabels];
    if (i < newSwitchLabels.length) {
      newSwitchLabels[i] = event.target.value;
      console.log("newSwitchLabels : ", newSwitchLabels);
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          switchLabels: newSwitchLabels,
        },
      }));
    }
  }

  handleSwitchValueChange(event, i) {
    console.log("i : ", i);
    const newSwitchValues = [...this.state.formDatas.switchValues];
    if (i < newSwitchValues.length) {
      newSwitchValues[i] = !newSwitchValues[i];
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          switchValues: newSwitchValues,
        },
      }));
    }
  }

  addSwitch(event, i) {
    event.preventDefault();
    const newSwitchLabels = [...this.state.formDatas.switchLabels];
    newSwitchLabels.push("");
    const newSwitchValues = [...this.state.formDatas.switchValues];
    newSwitchValues.push(false);
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        switchValues: newSwitchValues,
        switchLabels: newSwitchLabels,
      },
    }));
  }

  removeSwitch(event, i) {
    event.preventDefault();
    if (i) {
      const newSwitchLabels = [...this.state.formDatas.switchLabels];
      newSwitchLabels.splice(i, 1);
      const newSwitchValues = [...this.state.formDatas.switchValues];
      newSwitchValues.splice(i, 1);
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          switchValues: newSwitchValues,
          switchLabels: newSwitchLabels,
        },
      }));
    }
  }

  handlePotentiometerLabelChange(event, i) {
    event.preventDefault();
    const newPotentiometerLabels = [
      ...this.state.formDatas.potentiometerLabels,
    ];
    if (i < newPotentiometerLabels.length) {
      newPotentiometerLabels[i] = event.target.value;
      console.log("newPotentiometerLabels : ", newPotentiometerLabels);
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          potentiometerLabels: newPotentiometerLabels,
        },
      }));
    }
  }

  handlePotentiometerValueChange(event, i) {
    event.preventDefault();
    const newPotentiometerValues = [
      ...this.state.formDatas.potentiometerValues,
    ];
    if (
      i < newPotentiometerValues.length &&
      event.target.value >= 0 &&
      event.target.value <= 10
    ) {
      newPotentiometerValues[i] = event.target.value;
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          potentiometerValues: newPotentiometerValues,
        },
      }));
    }
  }

  addPotentiometer(event, i) {
    event.preventDefault();
    const newPotentiometerLabels = [
      ...this.state.formDatas.potentiometerLabels,
    ];
    newPotentiometerLabels.push("");
    const newPotentiometerValues = [
      ...this.state.formDatas.potentiometerValues,
    ];
    newPotentiometerValues.push(0);
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        potentiometerValues: newPotentiometerValues,
        potentiometerLabels: newPotentiometerLabels,
      },
    }));
  }

  removePotentiometer(event, i) {
    event.preventDefault();
    if (i) {
      const newPotentiometerLabels = [
        ...this.state.formDatas.potentiometerLabels,
      ];
      newPotentiometerLabels.splice(i, 1);
      const newPotentiometerValues = [
        ...this.state.formDatas.potentiometerValues,
      ];
      newPotentiometerValues.splice(i, 1);
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          potentiometerValues: newPotentiometerValues,
          potentiometerLabels: newPotentiometerLabels,
        },
      }));
    }
  }

  vintageImageSelected(event) {
    event.preventDefault();

    console.log(1);

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    console.log(2);

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 2) {
      console.log(3);
      this.setState((prevState) => ({
        ...prevState,
        showVintageImageTooBig: true,
      }));

      return;
    }

    if (
      this.state.formDatas.youtubeVideoUrl ||
      this.state.formDatas.coordsLongitudeArObject ||
      this.state.formDatas.coordsLatitudeArObject ||
      this.state.formDatas.arImageRecognition.imageFile ||
      this.state.formDatas.arImageRecognition.imageUrl ||
      this.state.formDatas.arObject.file ||
      this.state.formDatas.arObject.fileUrl
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });

      return;
    }
    console.log("file:  ", event.target.files[0]);

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      showVintageImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        vintage: {
          ...prevState.formDatas.vintage,
          imageFile: event.target.files[0],
          imageDeleted: false,
        },
      },
    }));
  }

  arImageRecognitionSelected(event) {
    event.preventDefault();

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 2) {
      this.setState((prevState) => ({
        ...prevState,
        showArRecognitionImageTooBig: true,
      }));

      return;
    }

    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.youtubeVideoUrl
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      showArRecognitionImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        arImageRecognition: {
          ...prevState.formDatas.arImageRecognition,
          imageFile: event.target.files[0],
          imageDeleted: false,
        },
      },
    }));
  }

  clueImageSelected(event) {
    event.preventDefault();

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 2) {
      this.setState((prevState) => ({
        ...prevState,
        showClueImageTooBig: true,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      showClueImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        clue: {
          ...prevState.formDatas.clue,
          imageFile: event.target.files[0],
          imageDeleted: false,
        },
      },
    }));
  }

  arObjectSelected(event) {
    event.preventDefault();

    if (
      this.state.formDatas.vintage.imageFile ||
      this.state.formDatas.vintage.imageUrl ||
      this.state.formDatas.youtubeVideoUrl
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous ne pouvez ajouter qu'un seul média par énigme. Veuillez retirer l'autre avant d'en ajouter un nouveau.`,
        showSuccess: false,
      }));
      this.state.endOfPageRef.current.scrollIntoView({ behavior: "smooth" });

      return;
    }

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 8) {
      this.setState((prevState) => ({
        ...prevState,
        showArObjectTooBig: true,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      showClueImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        arObject: {
          ...prevState.formDatas.clue,
          file: event.target.files[0],
          arObjectDeleted: false,
        },
      },
    }));
  }

  handleSelectResponseTypeChange(event, value) {
    event.preventDefault();

    let type = EnigmaType.TEXT;
    if (event.target.value == "picker") {
      type = EnigmaType.PICKER;
    } else if (event.target.value == "compass") {
      type = EnigmaType.COMPASS;
    } else if (event.target.value == "switch") {
      type = EnigmaType.SWITCH;
    } else if (event.target.value == "potentiometer") {
      type = EnigmaType.POTENTIOMETER;
    }

    this.setState((prevState) => ({
      formDatas: {
        ...prevState.formDatas,
        unsavedChanges: true,
        responseType: event.target.value,
        type: type,
      },
    }));
  }

  deleteImage(deleteClueImage, deleteVintageImage, deleteArRecognitionImage) {
    if (deleteClueImage) {
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          clue: {
            ...prevState.formDatas.clue,
            imageFile: null,
            imageUrl: null,
            imageDeleted: true,
          },
        },
      }));
    }
    if (deleteVintageImage) {
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          vintage: {
            ...prevState.formDatas.vintage,
            imageFile: null,
            imageUrl: null,
            imageDeleted: true,
          },
        },
      }));
    }
    if (deleteArRecognitionImage) {
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: true,
        formDatas: {
          ...prevState.formDatas,
          arImageRecognition: {
            ...prevState.formDatas.arImageRecognition,
            imageFile: null,
            imageUrl: null,
            imageDeleted: true,
          },
        },
      }));
    }
  }

  deleteObjFile() {
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        arObject: {
          ...prevState.formDatas.clue,
          imageFile: null,
          imageUrl: null,
          fileName: null,
          arObjectDeleted: true,
        },
      },
    }));
  }

  isResponsePickerValid() {
    var i = 0;
    console.log(
      "this.state.formDatas.pickerChoices ",
      this.state.formDatas.pickerChoices
    );
    for (let response of this.state.formDatas.responsesPicker) {
      var foundResponse = false;
      console.log("i ", i);
      for (let choice of this.state.formDatas.pickerChoices[i].value.split(
        ";"
      )) {
        if (choice === response) {
          foundResponse = true;
        }
      }
      if (!foundResponse) {
        return false;
      }
      i++;
    }
    return true;
  }

  async submitForm(event) {
    event.preventDefault();

    if (this.state.role === "NO_SUBSCRIBTION" || this.state.tourDisabled) {
      return;
    }

    var serverResp = null;

    if (this.state.formDatas.responseType === "picker") {
      let isValid = this.isResponsePickerValid();
      if (!isValid) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: `Les propositions de réponses ne contiennent pas la bonne réponse`,
          showSuccess: false,
        }));
        return;
      }
    }
    if (
      (this.state.formDatas.arImageRecognition.imageFile ||
        this.state.formDatas.arImageRecognition.imageUrl) &&
      !this.state.formDatas.arObject.file &&
      !this.state.formDatas.arObject.fileUrl
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous devez ajouter un fichier 3D si vous souhaitez ajouter une image à reconnaitre en réalité augmentée`,
        showSuccess: false,
      }));
      return;
    }
    if (
      (this.state.formDatas.coordsLongitudeArObject ||
        this.state.formDatas.coordsLatitudeArObject) &&
      !this.state.formDatas.arObject?.file &&
      !this.state.formDatas.arObject?.fileUrl
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Vous avez renseigné une localisation d'objet 3D mais vous n'avez pas ajouté d'objet 3D.`,
        showSuccess: false,
      }));
      return;
    }
    if (
      (this.state.formDatas.coordsLongitudeArObject &&
        !this.state.formDatas.coordsLatitudeArObject) ||
      (!this.state.formDatas.coordsLongitudeArObject &&
        this.state.formDatas.coordsLatitudeArObject)
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Si vous saisissez une latitude ou une longitude, vous devez entrer les deux coordonnées.`,
        showSuccess: false,
      }));
      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      loadingViewOpened: true,
    }));

    if (this.state.editing) {
      serverResp = await ApiEnigma.updateEnigma(
        this.state.formDatas,
        this.state.formDatas.enigmaId
      );
    } else {
      serverResp = await ApiEnigma.uploadEnigma(this.state.formDatas);
    }

    this.setState((prevState) => ({
      ...prevState,
      loadingViewOpened: false,
    }));
    if (serverResp.status != 201 && serverResp.status != 200) {
      if (serverResp.status === 403) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: `Nombre maximum d'énigmes atteint`,
          showSuccess: false,
        }));
        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Une erreur est survenue lors de la communication avec le serveur`,
        showSuccess: false,
      }));

      return;
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: true,
        unsavedChanges: false,
        editing: true,
        showError: false,
        errorMessage: "",
        successMessage: "L'énigme a bien été sauvegardée",
        editing: true,
        formDatas: {
          ...prevState.formDatas,
          enigmaId: serverResp.data.enigmaId,
          uuid: serverResp.data.uuid,
        },
      }));
    }
  }

  isImageSet(localUrl, signedUrl) {
    if (ImageUtils.getImageUrlFromFile(localUrl)) {
      return true;
    } else if (ImageUtils.getImageUrlFromFile(signedUrl)) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <>
        <NewEnigmaView
          {...this.props}
          state={this.state}
          formDatas={this.state.formDatas}
          updateTitle={this.updateTitle}
          updatePoints={this.updatePoints}
          handleSelectResponseTypeChange={this.handleSelectResponseTypeChange}
          updateResponsesText={this.updateResponsesText}
          handleResponsesPickerTextChange={this.handleResponsesPickerTextChange}
          updatePickerChoice={this.updatePickerChoice}
          updateFinishText={this.updateFinishText}
          updateShowFinishOnLoose={this.updateShowFinishOnLoose}
          updateClueTitle={this.updateClueTitle}
          updateYoutubeVideoUrl={this.updateYoutubeVideoUrl}
          clueImageSelected={this.clueImageSelected}
          deleteImage={this.deleteImage}
          updateClueTime={this.updateClueTime}
          updateVintageTitle={this.updateVintageTitle}
          vintageImageSelected={this.vintageImageSelected}
          submitForm={this.submitForm}
          isImageSet={this.isImageSet}
          handleResponsesCompassChange={this.handleResponsesCompassChange}
          handleSwitchLabelChange={this.handleSwitchLabelChange}
          handleSwitchValueChange={this.handleSwitchValueChange}
          addSwitch={this.addSwitch}
          removeSwitch={this.removeSwitch}
          handlePotentiometerLabelChange={this.handlePotentiometerLabelChange}
          handlePotentiometerValueChange={this.handlePotentiometerValueChange}
          addPotentiometer={this.addPotentiometer}
          removePotentiometer={this.removePotentiometer}
          showLatOnMap={this.state.showLatOnMap}
          showLngOnMap={this.state.showLngOnMap}
          mapDialogOpen={this.state.mapDialogOpen}
          closeMapDialog={this.closeMapDialog}
          openMapDialog={this.openMapDialog}
          setArObjectLocation={this.setArObjectLocation}
          deleteObjFile={this.deleteObjFile}
          arObjectSelected={this.arObjectSelected}
          arImageRecognitionSelected={this.arImageRecognitionSelected}
          updateArCoordsLatitude={this.updateArCoordsLatitude}
          updateArCoordsLongitude={this.updateArCoordsLongitude}
          hasOtherMediaThanYT={this.hasOtherMediaThanYT}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NewEnigma);
