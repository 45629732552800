import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { AppBar, Box, Card, CardMedia, Typography } from "@material-ui/core";

const CheckYourMailsView = (props) => {
  return (
    <>
      <AppBar className={props.classes.appBar}>
        <Card className={props.classes.card}>
          <CardMedia
            component="img"
            alt="Description de l'image"
            height="40"
            width="40"
            image="/logo192.png"
            title="Titre de l'image"
          />
        </Card>
      </AppBar>
      <Box
        className={props.classes.backgroundImg}
        sx={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
          // height: "100vh",
        }}
      >
        <div>
          <Box
            className={props.classes.container}
            sx={{
              width: 500,
              bgcolor: "primary.main",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
            }}
          >
            <img
              className={props.classes.cardMedia}
              alt="Description de l'image"
              height="50"
              width="50"
              src="/images/mail.png"
              title="Titre de l'image"
            />
            <Typography className={props.classes.title}>
              Activation de votre compte
            </Typography>
            <Typography className={props.classes.subtitle}>
              Pour finaliser la création de votre compte, veuillez vérifier
              votre boîte de réception. Un e-mail contenant un lien de
              validation vous a été envoyé. Cliquez sur ce lien pour activer
              votre compte et commencer à utiliser nos services. Si vous ne
              trouvez pas l'e-mail, pensez à vérifier votre dossier de spam.
            </Typography>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default CheckYourMailsView;
