import React from "react";
import { Box, Grid } from "@material-ui/core";

const NoSubscriptionBanner = ({ role, redirectToSelectedPack, classes }) => {
  return (
    <Box>
      {role === "NO_SUBSCRIBTION" && (
        <Box className={classes.banner}>
          {redirectToSelectedPack ? (
            <>
              Vous pouvez continuer votre souscription en cliquant{" "}
              <a
                style={{ color: "white" }}
                href={`/subscribe?pack=${redirectToSelectedPack}`}
              >
                ici
              </a>
              .
            </>
          ) : (
            <>
              Votre compte n'a pas d'abonnement actif. Vous pouvez souscrire à
              un abonnement en cliquant{" "}
              <a style={{ color: "white" }} href="/subscribe">
                ici
              </a>
              .
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NoSubscriptionBanner;
