import React from "react";
import Alert from "@material-ui/lab/Alert";

import DraggableList from "../../../../components/draggableList";
import DialogWarning from "../../../../components/dialogWarning";
import { Box, Typography } from "@material-ui/core";

const ParcoursListView = (props) => {
  return (
    <>
      <DialogWarning
        open={props.state.dialogWaringOpened}
        primaryText="Voulez vous vraiment supprimer ce circuit ?"
        secondaryText="Une fois supprimé il sera impossible de le récupérer."
        primaryAction={props.deleteTour}
        closeAction={props.closeDeleteTourDialog}
        idItem={props.state.tourIdToDelete}
        primaryButtonText="Supprimer"
        secondaryButtonText="Annuler"
      />
      {props.state.showError ? (
        <Alert severity="error">{props.state.errorMessage}</Alert>
      ) : (
        <div />
      )}
      {props.state.showSuccess ? (
        <Alert severity="success">{props.state.successMessage}</Alert>
      ) : (
        <div />
      )}

      <div
        style={{
          borderRadius: 5,
          listStyle: "none",
          backgroundColor: "#00000000",
        }}
      >
        {props.state.toursLoaded &&
        props.toursItems &&
        props.toursItems.length ? (
          <DraggableList
            items={props.toursItems}
            onDragEnd={props.onDragEnd}
            onDelete={
              props.role === "NO_SUBSCRIBTION"
                ? null
                : props.openDeleteTourDialog
            }
            onDuplicate={
              props.role === "NO_SUBSCRIBTION" ? null : props.duplicateTour
            }
            onEdit={props.onEdit}
            onClick={props.onTourSelection}
            selectedId={props.tourIdSelected}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#00000000",
            }}
          >
            <Typography
              style={{
                color: "white",
                fontWeight: 100,
                backgroundColor: "#00000000",
              }}
            >
              Aucun parcours
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
};

export default ParcoursListView;
