import React, { useState, useEffect } from "react";
import {
  Popover,
  Checkbox,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";

import ApiUser from "../API/ApiUser";
import ApiTour from "../API/ApiTour";
import { Close } from "@material-ui/icons";

const DisableToursPopover = ({ userOpenedPopover = false }) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [tours, setTours] = useState([]);
  const [maxTours, setMaxTours] = useState(0);
  const [maxToursTitle, setMaxToursTitle] = useState(0);
  const [remainingToursToSelect, setRemainingToursToSelect] = useState(0);

  useEffect(() => {
    setPopoverVisible(userOpenedPopover);
    getTours();
  }, [userOpenedPopover]);

  useEffect(() => {
    getTours();
  }, []);

  const getTours = () => {
    ApiUser.needToDisableSomeTours().then((res) => {
      console.log("userOpenedPopover ", userOpenedPopover);
      if (res.data.needToDisableSomeTours || userOpenedPopover) {
        setPopoverVisible(true);

        // Récupération des tours
        ApiTour.getTours().then((res) => {
          setTours(res.data);
          console.log("user tours ", res.data);
          // Récupération de l'account utilisateur
          ApiUser.getAccount().then((accountRes) => {
            const maxTours = accountRes.data.maxTours;
            console.log("tours.len ", res.data.length);
            console.log("maxTours ", maxTours);
            if (res.data.length < maxTours) {
              // Nombre de parcours à choisir
              setMaxTours(res.data.length);
            } else {
              // Nombre de parcours à choisir
              setMaxTours(maxTours);
            }
            // Nombre max de parcours autorisés
            setMaxToursTitle(maxTours);

            // Calcul du nombre de tours activés
            if (res && res.status === 200) {
              const activeTours = res.data.filter(
                (tour) => !tour.disabled
              ).length;
              setRemainingToursToSelect(
                activeTours -
                  (res.data.length < maxTours ? res.data.length : maxTours)
              );
            }
          });
        });
      }
    });
  };

  const handleTourToggle = (tourId, checked) => {
    const updatedTours = tours.map((tour) =>
      tour.id === tourId ? { ...tour, disabled: !checked } : tour
    );
    setTours(updatedTours);

    console.log("updated tours : ", updatedTours);

    const selected = updatedTours.filter((tour) => !tour.disabled).length;
    setRemainingToursToSelect(selected - maxTours);
  };

  const handleSubmit = () => {
    const toursToDisable = tours.map((tour) => ({
      id: tour.id,
      disabled: tour.disabled,
    }));

    ApiUser.setDisabledTours(toursToDisable).then(() => {
      setPopoverVisible(false);
      console.log("close popover");
      window.location.reload();
    });
  };

  const isSubmitEnabled = remainingToursToSelect === 0;

  return (
    <>
      {isPopoverVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 999,
          }}
        ></div>
      )}
      <Popover
        open={isPopoverVisible}
        onClose={userOpenedPopover ? () => setPopoverVisible(false) : null}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: window.innerHeight / 2,
          left: window.innerWidth / 2,
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "20px", width: "50vw" }}>
          {userOpenedPopover && (
            <IconButton
              onClick={() => setPopoverVisible(false)}
              style={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <Close />
            </IconButton>
          )}

          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "800",
              textAlign: "center",
              marginTop: "12px",
            }}
          >
            Cochez les parcours que vous souhaitez garder.
          </Typography>
          <Typography
            style={{
              fontSize: "15px",
              fontWeight: "600",
              textAlign: "center",
              margin: "12px",
            }}
          >
            Votre abonnement vous donne droit à {maxToursTitle} parcours. Vous
            devez donc sélectionner {maxTours} parcours que vous pourrez
            continuer à utiliser. Les autres parcours resteront visibles dans
            votre interface, mais ne seront pas modifiables, et les codes
            générés pour ces parcours ne seront plus utilisables.
          </Typography>
          <div
            style={{
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.4)",
              backgroundColor: "#F9F8FF",
              borderRadius: "8px",
              margin: "32px",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "center",
                  marginTop: "12px",
                }}
              >
                {remainingToursToSelect > 0
                  ? "Parcours à désélectionner : "
                  : remainingToursToSelect < 0
                  ? "Parcours à sélectionner : "
                  : ""}
              </Typography>
              {remainingToursToSelect !== 0 && (
                <div
                  style={{
                    backgroundColor: "#F48A21",
                    width: "32px",
                    height: "32px",
                    marginTop: "12px",
                    marginLeft: "12px",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {remainingToursToSelect > 0
                      ? remainingToursToSelect
                      : remainingToursToSelect < 0
                      ? -remainingToursToSelect
                      : ""}
                  </Typography>
                </div>
              )}
            </div>
            {tours?.map((tour) => (
              <div key={tour.id}>
                <Checkbox
                  style={{
                    color: "#F48A21", // Couleur lorsque cochée
                  }}
                  checked={!tour.disabled}
                  onChange={(e) => handleTourToggle(tour.id, e.target.checked)}
                ></Checkbox>
                {tour.title}
              </div>
            ))}
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              🚨 Attention, vous ne pouvez modifier ce choix qu'une seule fois
              par semaine.
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={!isSubmitEnabled}
                variant="contained"
                style={{
                  fontSize: "20px",
                  fontWeight: "800",
                  color: "#ffffff",
                  backgroundColor: !isSubmitEnabled ? "#aaaaaa" : "#F48A21",
                  borderRadius: 24,
                  "&:hover": {
                    backgroundColor: "#ab723a",
                  },
                }}
              >
                Valider
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DisableToursPopover;
