import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  CardMedia,
  Card,
  Typography,
  Button,
  Slider,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ImageUtils from "../../../utils/imageUtils";
import DraggableList from "../../components/draggableList";
import ListItemLink from "../../components/listItemLink";
import DialogWarning from "../../components/dialogWarning";
import LoadingView from "../../components/loadingView";
import MainAppBar from "../../components/MainAppBar";
import { GameMode } from "../../../constants/TourConstants";
import BreadcrumbTour from "../../components/breadcrumTour";
import SouthWestIcon from "../../../utils/southWestIcon";
import SelectLocationDialog from "../../components/selectOnMapDialog";
import { Map } from "@material-ui/icons";
import UnsavedChangesIndicator from "../../components/unsevedChangesIndicator";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import InfoPopover from "../../../utils/informationButton";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import DisableToursPopover from "../../../utils/disableToursPopover";

const TourView = (props) => {
  const zonesItems = props.getZonesItems();

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }

  return (
    <>
      <MainAppBar
        headerText="Parcours"
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <NoSubscriptionBanner
        role={props.state.role}
        redirectToSelectedPack={null}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Container className={props.classes.formContainer} maxWidth={false}>
        <UnsavedChangesIndicator show={props.state.unsavedChanges} />
        <Prompt
          when={props.state.unsavedChanges}
          message="Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?"
        />
        <BreadcrumbTour
          tourId={props.state.formDatas ? props.state.formDatas.tourId : null}
          zoneId={props.zoneId}
          enigmaId={props.enigmaId}
        />
        <br></br>
        <form
          className={props.classes.form}
          autoComplete="off"
          onSubmit={props.submitForm}
        >
          {props.state.isAdmin ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: 6 }}
                control={
                  <Checkbox
                    name="mustAddAnalyticBeforeLaunch"
                    checked={props.state.formDatas.mustAddAnalyticBeforeLaunch}
                    onChange={props.updateMustAddAnalyticBeforeLaunch}
                    color="primary"
                  />
                }
                label="Envois des stats bloquant"
              />
              <FormControlLabel
                style={{ marginLeft: 6 }}
                control={
                  <Checkbox
                    name="needDownloadMapTiles"
                    checked={props.state.formDatas.needDownloadMapTiles}
                    onChange={props.updateNeedDownloadMapTiles}
                    color="primary"
                  />
                }
                label="Téléchargement de la carte sur l'appareil"
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: 10,
            }}
          >
            <FormControlLabel
              style={{ marginLeft: 6 }}
              control={
                <Checkbox
                  name="cluesFree"
                  checked={props.state.formDatas.cluesFree}
                  onChange={props.updateCluesFree}
                  color="primary"
                />
              }
              label="Indices gratuits"
            />
            <InfoPopover
              title="Indices gratuit"
              text="En cochant cette case, l'ouverture des indices ne fera pas perdre de points aux joueurs."
            ></InfoPopover>
          </div>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Box className={props.classes.textFieldContainer}>
                <TextField
                  required
                  id="filled-required"
                  label="Titre du jeu"
                  defaultValue={props.state.formDatas.title}
                  onChange={props.updateTitle}
                  variant="outlined"
                  size="small"
                  className={props.classes.textFieldLight}
                  style={{ width: "95%" }}
                  key={
                    props.state.tourLoaded ? "titleNotLoaded" : "titleLoaded"
                  }
                />
                <InfoPopover
                  title="Description du jeu"
                  text="Il s'agit du texte qui sera affiché à l'utilisateur avant de commencer le parcours. Il est possible d'utiliser des markdowns pour modifier l'affichage du texte dans l'application. Voici un exemple d'affichage avec ce texte : 
                  \nLien contact : [landingzone](https://landingzone.fr/contact/)
                  \n# Titre 1
                    \n## Titre 2
                    \n*Italique*
                    **Gras**
                    Émoji : ⚙️
                    ~~Barré~~
                    \n> Citation
                    "
                  isWhite={true}
                  src="/images/game-tour-markdown.webp"
                ></InfoPopover>
                <TextField
                  required
                  id="filled-required"
                  label="Description du jeu"
                  defaultValue={props.state.formDatas.subtitle}
                  onChange={props.updateSubtitle}
                  variant="outlined"
                  size="small"
                  multiline={true}
                  minRows={5}
                  inputProps={{ maxLength: 2048 }}
                  className={props.classes.textFieldLight}
                  style={{ width: "95%" }}
                  key={
                    props.state.tourLoaded
                      ? "subtitleNotLoaded"
                      : "subtitleLoaded"
                  }
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  marginBottom: 10,
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "95%", backgroundColor: "white" }}
                >
                  <InputLabel id="select-response-type">Type de jeu</InputLabel>
                  <Select
                    labelId="select-response-type"
                    id="select-response-type"
                    value={props.state.formDatas.gameMode}
                    onChange={props.handleGameModeChange}
                  >
                    <MenuItem value={GameMode.MAP_AND_QRCODE}>
                      Map et QRCodes
                    </MenuItem>
                    <MenuItem value={GameMode.ONLY_QRCODE}>
                      Seulement QRCodes
                    </MenuItem>
                    <MenuItem value={GameMode.AUTOMATIC_LAUNCH}>
                      Déclenchement automatique
                    </MenuItem>
                  </Select>
                </FormControl>
                {props.state.formDatas.gameMode == GameMode.ONLY_QRCODE ? (
                  <>
                    {" "}
                    <InfoPopover
                      title="Seulement QR Code"
                      text="Dans ce mode de jeu, les joueurs doivent scanner des QR Codes pour déclencher les énigmes. Lorsque les joueurs sont hors zone, l'image d'arrière-plan est affichée en jeu."
                    ></InfoPopover>
                  </>
                ) : props.state.formDatas.gameMode ==
                  GameMode.MAP_AND_QRCODE ? (
                  <>
                    <InfoPopover
                      title="Map et QR Codes"
                      text="Dans ce mode de jeu, les joueurs doivent aller dans des zones géographiques ou scanner des QR Codes pour déclencher les énigmes. Si vous ne spécifiez pas de zones à QR Code, le bouton de scan ne sera pas affiché en jeu."
                    ></InfoPopover>
                  </>
                ) : props.state.formDatas.gameMode ==
                  GameMode.AUTOMATIC_LAUNCH ? (
                  <>
                    <InfoPopover
                      title="Déclenchement automatique"
                      text="Dans ce mode de jeu, les énigmes se lancent les unes après les autres."
                    ></InfoPopover>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Grid
            container
            spacing={4}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Grid
              direction="column"
              item
              xs={6}
              style={{
                justifyContent: "center",
                alignItems: "center",

                display: "flex",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                error={true}
                style={{
                  color: "white",
                  width: "50%",
                }}
                className={props.classes.uploadFile}
                startIcon={<CloudUploadIcon />}
                component="label"
              >
                Image d'arrière plan
                <input
                  type="file"
                  name="file1"
                  onChange={props.backgroundImageSelected}
                  hidden
                  accept=".png,.jpg,.jpeg"
                />
              </Button>
              <Typography
                className={props.classes.imageDimensionsText}
                id="discrete-slider"
                gutterBottom
              >
                Taille maximum: 2mo
              </Typography>
              <Typography
                className={props.classes.imageDimensionsText}
                id="discrete-slider"
                gutterBottom
              >
                Dimensions recommandées (téléphone): 411x794px
                <br />
                Dimensions recommandées (tablette): 1024x1310px
              </Typography>
              {props.state.showBackgroundImageTooBig ? (
                <Typography
                  className={props.classes.imageDimensionsText}
                  style={{ color: "red" }}
                  gutterBottom
                >
                  L'image ne doit pas éxcéder 2mo
                </Typography>
              ) : (
                <div />
              )}

              <Grid
                item
                xs={12}
                direction="row"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  width: "100%",
                }}
              >
                <Container>
                  <Typography className={props.classes.imageInfos} gutterBottom>
                    Affichage de l'image avant le lancement de la partie (sur
                    téléphone)
                  </Typography>
                  <Card className={props.classes.imageCardBackground}>
                    <CardMedia
                      className={props.classes.imageCardMedia}
                      image={
                        ImageUtils.getImageUrlFromFile(
                          props.state.formDatas.backgroundImageFile
                        )
                          ? ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                          : props.state.formDatas.backgroundImageUrl
                      }
                      title="BackgroundImage"
                    />
                  </Card>
                </Container>
                <Container>
                  <Typography className={props.classes.imageInfos} gutterBottom>
                    Affichage de l'image avant le lancement de la partie (sur
                    tablette)
                  </Typography>
                  <Card className={props.classes.imageCardBackgroundTab}>
                    <CardMedia
                      className={props.classes.imageCardMedia}
                      image={
                        ImageUtils.getImageUrlFromFile(
                          props.state.formDatas.backgroundImageFile
                        )
                          ? ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                          : props.state.formDatas.backgroundImageUrl
                      }
                      title="BackgroundImage"
                    />
                  </Card>
                </Container>
              </Grid>

              <Grid
                item
                xs={12}
                direction="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Container>
                  <Typography className={props.classes.imageInfos} gutterBottom>
                    Affichage de l'image dans la liste des parcours (sur
                    téléphone)
                  </Typography>
                  <Card className={props.classes.imageCardBackgroundInList}>
                    <CardMedia
                      className={props.classes.imageCardMedia}
                      image={
                        ImageUtils.getImageUrlFromFile(
                          props.state.formDatas.backgroundImageFile
                        )
                          ? ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                          : props.state.formDatas.backgroundImageUrl
                      }
                      title="BackgroundImage"
                    />
                  </Card>
                </Container>
                <Container>
                  <Typography className={props.classes.imageInfos} gutterBottom>
                    Affichage de l'image dans la liste des parcours (sur
                    tablette)
                  </Typography>
                  <Card className={props.classes.imageCardBackgroundInListTab}>
                    <CardMedia
                      className={props.classes.imageCardMedia}
                      image={
                        ImageUtils.getImageUrlFromFile(
                          props.state.formDatas.backgroundImageFile
                        )
                          ? ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                          : props.state.formDatas.backgroundImageUrl
                      }
                      title="BackgroundImage"
                    />
                  </Card>
                </Container>
              </Grid>
            </Grid>
            {props.state.formDatas.gameMode == GameMode.ONLY_QRCODE ||
            props.state.formDatas.gameMode == GameMode.AUTOMATIC_LAUNCH ? (
              <></>
            ) : (
              <Grid
                direction="column"
                item
                xs={3}
                style={{
                  display: "flex",
                }}
              >
                <>
                  <Button
                    variant="contained"
                    className={props.classes.uploadFile}
                    style={{
                      color: "white",
                      width: "50%",
                    }}
                    startIcon={<CloudUploadIcon />}
                    component="label"
                  >
                    Image point de départ
                    <input
                      type="file"
                      name="file3"
                      onChange={props.annotationImageSelected}
                      hidden
                      accept=".png,.jpg,.jpeg"
                    />
                  </Button>
                  <Typography
                    className={props.classes.imageDimensionsText}
                    align="inherit"
                    gutterBottom
                  >
                    Taille maximum: 500ko
                  </Typography>
                  <Typography
                    className={props.classes.imageDimensionsText}
                    gutterBottom
                  >
                    Dimensions recommandées (téléphone et tablette): 75x75px
                  </Typography>
                  <Typography className={props.classes.imageInfos} gutterBottom>
                    Affichage sur téléphone et tablette
                  </Typography>
                  {props.state.showAnnotationImageTooBig ? (
                    <Typography
                      className={props.classes.imageDimensionsText}
                      style={{ color: "red" }}
                      gutterBottom
                    >
                      L'image ne doit pas éxcéder 500ko
                    </Typography>
                  ) : (
                    <div />
                  )}
                  <Card className={props.classes.imageCardAnnotation}>
                    <CardMedia
                      className={props.classes.imageCardMedia}
                      image={
                        ImageUtils.getImageUrlFromFile(
                          props.state.formDatas.annotationImageFile
                        )
                          ? ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.annotationImageFile
                            )
                          : props.state.formDatas.annotationImageUrl
                      }
                      title="AnnotationImage"
                    />
                  </Card>
                  <br />

                  <Grid container spacing={2} direction="column">
                    <Grid direction="row">
                      {" "}
                      <TextField
                        required
                        id="standard-helperText"
                        label="Nom départ"
                        defaultValue={props.state.formDatas.homeAnnotationName}
                        onChange={props.updateHomeAnnotationName}
                        variant="outlined"
                        size="small"
                        className={props.classes.textfieldWhite}
                        key={
                          props.state.tourLoaded
                            ? "homeAnnottationNameLoaded"
                            : "homeAnnottationNameNotLoaded"
                        }
                      />
                      <br />
                    </Grid>

                    <Grid direction="row">
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography className={props.classes.headerLocations}>
                          Point de départ
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            required
                            id="filled-required"
                            label="Latitude"
                            onChange={props.updateHomeCoordsLatitude}
                            value={
                              props.state.formDatas.homeCoords.latitude != 0
                                ? props.state.formDatas.homeCoords.latitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            variant="outlined"
                            size="small"
                            style={{ width: "100px" }}
                            inputProps={{
                              className: props.classes.input,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.tourLoaded
                                ? "latHomeCoordsNotLoaded"
                                : "latHomeCoordsLoaded"
                            }
                          />
                          <TextField
                            required
                            id="filled-required"
                            label="Longitude"
                            onChange={props.updateHomeCoordsLongitude}
                            value={
                              props.state.formDatas.homeCoords.longitude != 0
                                ? props.state.formDatas.homeCoords.longitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            style={{ width: "100px" }}
                            variant="outlined"
                            size="small"
                            key={
                              props.state.tourLoaded
                                ? "lonHomeCoordsNotLoaded"
                                : "lonHomeCoordsLoaded"
                            }
                          />
                          <Button
                            onClick={() => props.openMapDialog("HOME")}
                            className={props.classes.mapButton}
                            startIcon={<Map />}
                          >
                            Sélectionner
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid direction="row">
                      {" "}
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="redirectToHomeWhileFinished"
                              checked={
                                props.state.formDatas
                                  .redirectToHomeWhileFinished
                              }
                              onChange={props.updateRedirectToHomeWhileFinished}
                              color="primary"
                            />
                          }
                          label="Redirection vers l'enseigne en fin de partie"
                        />
                        <InfoPopover
                          title="Redirection vers l'enseigne"
                          text="Si vous cochez cette case, les utilisateurs verront l'image de point de départ sur la carte en fin de partie. Ils seront alors invités à rejoindre ce point avant d'aller à la page de fin de partie."
                          src="/images/fin-de-partie-avec-redirect.webp"
                        ></InfoPopover>
                      </Box>
                      <br />
                    </Grid>
                  </Grid>

                  <Typography
                    className={props.classes.title}
                    align="inherit"
                    gutterBottom
                  >
                    Limites de la carte
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid direction="row">
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography className={props.classes.headerLocations}>
                          Limite de la carte Nord-Est
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            required
                            id="filled-required"
                            label="Latitude"
                            value={
                              props.state.formDatas.cameraBoundsNE.latitude != 0
                                ? props.state.formDatas.cameraBoundsNE.latitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            onChange={props.updateCameraBoundsNELatitude}
                            variant="outlined"
                            style={{ width: "100px" }}
                            size="small"
                            inputProps={{
                              className: props.classes.input,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.tourLoaded
                                ? "latCamNELoaded"
                                : "latCamNENotLoaded"
                            }
                          />{" "}
                          <TextField
                            required
                            id="filled-required"
                            label="Longitude"
                            // helperText="Ces coordonnées définissent les limites de la map afficher à l'utilisateur."
                            value={
                              props.state.formDatas.cameraBoundsNE.longitude !=
                              0
                                ? props.state.formDatas.cameraBoundsNE.longitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            style={{ width: "100px" }}
                            onChange={props.updateCameraBoundsNELongitude}
                            variant="outlined"
                            size="small"
                            inputProps={{
                              className: props.classes.input,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.tourLoaded
                                ? "lonCamNELoaded"
                                : "lonCamNENotLoaded"
                            }
                          />
                          <Button
                            onClick={() => props.openMapDialog("NE")}
                            className={props.classes.mapButton}
                            startIcon={<Map />}
                          >
                            Sélectionner
                          </Button>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid direction="row">
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography className={props.classes.headerLocations}>
                          Limite de la carte Sud-Ouest
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            required
                            id="filled-required"
                            label="Latitude"
                            value={
                              props.state.formDatas.cameraBoundsSW.latitude != 0
                                ? props.state.formDatas.cameraBoundsSW.latitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            style={{ width: "100px" }}
                            onChange={props.updateCameraBoundsSWLatitude}
                            variant="outlined"
                            size="small"
                            inputProps={{
                              className: props.classes.input,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.tourLoaded
                                ? "latCamSWLoaded"
                                : "latCamSWNotLoaded"
                            }
                          />
                          <TextField
                            required
                            id="filled-required"
                            label="Longitude"
                            value={
                              props.state.formDatas.cameraBoundsSW.longitude !=
                              0
                                ? props.state.formDatas.cameraBoundsSW.longitude
                                : ""
                            }
                            style={{ width: "100px" }}
                            onChange={props.updateCameraBoundsSWLongitude}
                            variant="outlined"
                            size="small"
                            className={props.classes.textfieldWhite}
                            inputProps={{
                              className: props.classes.input,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.tourLoaded
                                ? "lonCamSWLoaded"
                                : "lonCamSWNotLoaded"
                            }
                          />
                          <Button
                            onClick={() => props.openMapDialog("SW")}
                            className={props.classes.mapButton}
                            startIcon={<Map />}
                          >
                            Sélectionner
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Typography className={props.classes.helperText}>
                    Ces coordonnées définissent les limites de la carte en jeu
                  </Typography>
                </>
              </Grid>
            )}
            <Grid
              direction="column"
              item
              xs={3}
              style={{
                display: "flex",
              }}
            >
              <Typography
                id="discrete-slider"
                style={{ fontWeight: 700 }}
                gutterBottom
              >
                Temps de jeu
              </Typography>
              <TextField
                required
                id="filled-required"
                label="Minutes"
                variant="outlined"
                size="small"
                type="number"
                value={props.state.formDatas.timer}
                onChange={props.updateTimer}
                className={props.classes.textField}
                InputProps={{
                  inputProps: {
                    max: 600,
                    min: 0,
                  },
                }}
                key={props.state.zoneLoaded ? "timerNotLoaded" : "timerLoaded"}
              />
            </Grid>
          </Grid>
          <br></br> <br />
          <Divider />
          <Box className={props.classes.listContainer}>
            <div className={props.classes.list}>
              {props.state.zonesLoaded ? (
                <DraggableList
                  items={zonesItems}
                  onDragEnd={props.onDragEnd}
                  onDelete={props.openDeleteZoneDialog}
                />
              ) : (
                <div></div>
              )}
              <Box className={props.classes.buttonListPlus}>
                <ListItemLink
                  to={`/zone?tourId=${props.state.formDatas.tourId}`}
                  key={1000}
                  primary="NOUVELLE ZONE OU QR CODE"
                  icon={<AddCircleOutlineIcon />}
                  deleteButon={false}
                  disabled={
                    props.state.formDatas.tourId &&
                    props.state.role !== "NO_SUBSCRIBTION" &&
                    !props.state.formDatas?.disabled
                      ? false
                      : true
                  }
                />
              </Box>
            </div>
          </Box>
          <DialogWarning
            open={props.state.dialogWaringOpened}
            primaryText="Voulez vous vraiment supprimer cette zone ?"
            secondaryText="Une fois supprimé il sera impossible de la récupérer."
            primaryAction={props.deleteZone}
            closeAction={props.closeDeleteZoneDialog}
            idItem={props.state.zoneIdToDelete}
            primaryButtonText="Supprimer"
            secondaryButtonText="Annuler"
          />
          <LoadingView
            className={props.classes.loadingView}
            open={props.state.loadingViewOpened}
          />
          <br />
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
          <br />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className={`${props.classes.saveButton} ${
                props.state.role === "NO_SUBSCRIBTION" ||
                props.state.formDatas?.disabled
                  ? props.classes.buttonDisabled
                  : ""
              }`}
              type="submit"
            >
              {props.state.editing ? (
                <div>ENREGISTRER</div>
              ) : (
                <div>AJOUTER</div>
              )}
            </Button>
          </Box>
        </form>
      </Container>
      <SelectLocationDialog
        type={props.mapType}
        lat={props.showLatOnMap}
        lng={props.showLngOnMap}
        open={props.mapDialogOpen}
        onClose={() => props.closeMapDialog()}
        onLocationSelected={(location, type) => {
          if (type === "SW") {
            props.setCameraBoundsSWLocation(location);
          }
          if (type === "NE") {
            props.setCameraBoundsNELocation(location);
          }
          if (type === "HOME") {
            props.setHomeLocation(location);
          }
        }}
      />{" "}
    </>
  );
};

export default TourView;
