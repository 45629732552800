import React from "react";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";
import CategoryIcon from "@material-ui/icons/Category";

import ApiUser from "../../../API/ApiUser";
import ApiZone from "../../../API/ApiZone";
import ApiEnigma from "../../../API/ApiEnigma";
import { ZoneMode } from "../../../constants/TourConstants";
import styles from "./newZoneStyles";
import NewZoneView from "./newZoneView";
import ApiTour from "../../../API/ApiTour";
import ApiAdmin from "../../../API/ApiAdmin";

class NewZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      role: null,
      tourDisabled: null,
      showError: false,
      unsavedChanges: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      zoneLoaded: false,
      enigmasLoaded: false,
      enigmaOrderIdChanged: false,
      editing: false,
      tourId: 9999,
      zoneId: -1,
      gameMode: ZoneMode.MAP_AND_QRCODE,
      dialogWaringOpened: false,
      loadingViewOpened: false,
      enigmaIdToDelete: -1,
      qrCodeBgColor: "#ffffff",
      qrCodeFgColor: "#000000",
      tourLocationLat: null,
      tourLocationLng: null,
      tourMode: ZoneMode.MAP,
      qrCodeDialogOpen: false,
      selectedTourQrCode: null,
      selectedZoneQrCodeUuid: null,
      toursUsers: [],
      zonesSelectedQrTour: [],
      enigmasDatas: [
        {
          title: "",
          id: 9999,
          orderId: 0,
          isValid: false,
        },
      ],
      formDatas: {
        id: null,
        name: "",
        zoneRadius: 60,
        timer: 6,
        uuid: "",
        zoneMode: ZoneMode.MAP,
        coords: {
          longitude: 0,
          latitude: 0,
        },
        isActive: true,
        enigmasInRandomOrder: false,
      },
    };

    this.submitForm = this.submitForm.bind(this);

    this.updateName = this.updateName.bind(this);
    this.updateZoneRadius = this.updateZoneRadius.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.updateCoordsLon = this.updateCoordsLon.bind(this);
    this.updateCoordsLat = this.updateCoordsLat.bind(this);
    this.updateIsActive = this.updateIsActive.bind(this);
    this.updateEnigmasInRandomOrder =
      this.updateEnigmasInRandomOrder.bind(this);
    this.handleZoneModeChange = this.handleZoneModeChange.bind(this);
    this.handleQrCodeBackgroundColorChanged =
      this.handleQrCodeBackgroundColorChanged.bind(this);
    this.handleQrCodeForegroundColorChanged =
      this.handleQrCodeForegroundColorChanged.bind(this);
    this.handleChangeTourQrCode = this.handleChangeTourQrCode.bind(this);
    this.handleChangeZoneQrCode = this.handleChangeZoneQrCode.bind(this);
    this.handleValidateCopyUuid = this.handleValidateCopyUuid.bind(this);
    this.openQrCodeDialog = this.openQrCodeDialog.bind(this);
    this.handleCloseCopyUuid = this.handleCloseCopyUuid.bind(this);
    this.getZoneLinked = this.getZoneLinked.bind(this);
    this.handleResetZoneLinked = this.handleResetZoneLinked.bind(this);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.deleteEnigma = this.deleteEnigma.bind(this);
    this.openDeleteEnigmaDialog = this.openDeleteEnigmaDialog.bind(this);
    this.closeDeleteEnigmaDialog = this.closeDeleteEnigmaDialog.bind(this);
    this.downloadQR = this.downloadQR.bind(this);

    this.setLocation = this.setLocation.bind(this);
    this.openMapDialog = this.openMapDialog.bind(this);
    this.closeMapDialog = this.closeMapDialog.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    document.title = "Zone | Landing Zone";

    this.getRole();

    let tourId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).tourId;
    let zoneId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).zoneId;

    let tourIdInt = parseInt(tourId);
    let zoneIdInt = parseInt(zoneId);

    this.setStateIsAdmin();

    if (!tourId || !tourIdInt) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage:
          "Une erreur est survenue. Impossible de trouver les données liées à cette zone",
        showSuccess: false,
      }));
    }

    this.setState((prevState) => ({
      ...prevState,
      tourId: tourIdInt,
    }));

    ApiTour.getTour(tourIdInt).then((tourDatas) => {
      if (!tourDatas || tourDatas.status !== 200 || !tourDatas.data) {
        return;
      }
      ApiUser.isAdmin().then((responseIsAdmin) => {
        console.log("responseIsAdmin ", responseIsAdmin);
        if (
          responseIsAdmin.status === 200 &&
          responseIsAdmin.data.isAdmin === true
        ) {
          ApiAdmin.getToursClient(tourDatas.data.UserId).then((tours) => {
            if (tours.status === 200 && tours.data) {
              this.setState((prevState) => ({
                ...prevState,
                toursUsers: tours.data,
              }));
            }
          });
        } else {
          ApiTour.getTours().then((tours) => {
            if (tours.status === 200 && tours.data) {
              this.setState((prevState) => ({
                ...prevState,
                toursUsers: tours.data,
              }));
            }
          });
        }
      });
      console.log("tourDatas disabled", tourDatas.data.disabled);
      this.setState((prevState) => ({
        ...prevState,
        gameMode: tourDatas.data.gameMode,
        tourLocationLat: tourDatas.data.homeCoordsLatitude,
        tourLocationLng: tourDatas.data.homeCoordsLongitude,
        tourDisabled: tourDatas.data.disabled,
      }));
      if (tourDatas.data.gameMode == ZoneMode.QRCODE && !zoneIdInt) {
        this.setState((prevState) => ({
          ...prevState,
          formDatas: {
            ...prevState.formDatas,
            zoneMode: ZoneMode.QRCODE,
          },
        }));
      } else if (
        tourDatas.data.gameMode == ZoneMode.AUTOMATIC_LAUNCH &&
        !zoneIdInt
      ) {
        this.setState((prevState) => ({
          ...prevState,
          formDatas: {
            ...prevState.formDatas,
            zoneMode: ZoneMode.AUTOMATIC_LAUNCH,
          },
        }));
      }
    });

    if (!zoneId || !zoneIdInt) return;

    ApiZone.getZone(tourIdInt, zoneIdInt).then((zoneDatas) => {
      if (!zoneDatas || zoneDatas.status !== 200) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage:
            "Une erreur est survenue lors de la récupération du circuit",
          showSuccess: false,
        }));

        return;
      }

      this.setForm(zoneDatas.data, tourIdInt, zoneIdInt);
      this.getZoneLinked();
    });

    ApiEnigma.getEnigmas(zoneIdInt).then((enigmas) => {
      let enigmasDatas = [];

      if (enigmas.status !== 200 && enigmas.status !== 204) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage:
            "Une erreur est survenue lors de la récupération des énigmes",
          showSuccess: false,
        }));

        return;
      }

      if (enigmas.status == 204) return;

      enigmas.data.forEach((elem, i) => {
        enigmasDatas.push({
          title: elem.title,
          id: elem.id,
          isValid: elem.isValid,
        });
      });

      this.setState((prevState) => ({
        ...prevState,
        enigmasDatas: enigmasDatas,
        enigmasLoaded: true,
      }));
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  async getRole() {
    let response = await ApiUser.getAccount();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        role: response.data.role,
      }));
    }
  }

  handleBeforeUnload = (event) => {
    if (this.state.unsavedChanges) {
      const message =
        "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?";
      event.returnValue = message; // Standard pour la plupart des navigateurs
      return message; // Pour certains navigateurs
    }
  };

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  async setLocation(location) {
    if (!location || !location.lat || !location.lng) return;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        coords: {
          ...prevState.formDatas.homeCoords,
          longitude: location.lng,
          latitude: location.lat,
        },
      },
    }));
  }

  closeMapDialog() {
    this.setState((prevState) => ({
      ...prevState,
      mapDialogOpen: false,
    }));
  }

  openMapDialog(type) {
    const lat = this.state.formDatas.coords.latitude;
    const lng = this.state.formDatas.coords.longitude;
    console.log("coords : ", this.state.formDatas.coords);

    this.setState(
      (prevState) => ({
        ...prevState,
        mapDialogOpen: true,
        showLatOnMap: lat,
        showLngOnMap: lng,
      }),
      () => {}
    );
  }

  openQrCodeDialog() {
    console.log("OPEN");
    this.setState(
      (prevState) => ({
        ...prevState,
        qrCodeDialogOpen: true,
      }),
      () => {}
    );
  }

  async handleChangeTourQrCode(event) {
    event.preventDefault();
    const value = event.target.value;

    if (value) {
      ApiZone.getZones(value).then((zones) => {
        if (zones.status === 200 && zones.data) {
          console.log("zones.data : ", zones.data);
          this.setState((prevState) => ({
            ...prevState,
            zonesSelectedQrTour: zones.data,
            selectedTourQrCode: value,
          }));
        }
      });
    }
  }

  async handleChangeZoneQrCode(event) {
    event.preventDefault();
    const value = event.target.value;

    console.log("handleChangeZoneQrCode value : ", value);

    if (value) {
      this.setState((prevState) => ({
        ...prevState,
        selectedZoneQrCodeUuid: value,
      }));
    }
  }

  async getZoneLinked() {
    if (this.state.formDatas.uuid) {
      const response = await ApiZone.getZoneLinked(
        this.state.formDatas.uuid,
        this.state.tourId
      );
      if (response.status === 200 && response.data) {
        ApiZone.getZones(response.data.tourLinkedId).then((zones) => {
          if (zones.status === 200 && zones.data) {
            console.log(
              "response.data.tourLinkedId ",
              response.data.tourLinkedId
            );
            console.log("current zone id : ", this.state.formDatas.id);
            console.log("props.state.toursUsers ", this.state.toursUsers);
            this.setState((prevState) => ({
              ...prevState,
              zonesSelectedQrTour: zones.data,
              selectedTourQrCode: response.data.tourLinkedId,
              selectedZoneQrCodeUuid: this.state.formDatas.uuid,
            }));
          }
        });
      }
    }
  }

  async handleValidateCopyUuid() {
    if (this.state.selectedTourQrCode && this.state.selectedZoneQrCodeUuid) {
      // this.setState((prevState) => ({
      //   ...prevState,
      //   formDatas: {
      //     ...prevState.formDatas,
      //     uuid: this.state.selectedZoneQrCodeUuid,
      //   },
      // }));
      const zoneUpdated = this.state.formDatas;
      zoneUpdated.uuid = this.state.selectedZoneQrCodeUuid;
      const response = await ApiZone.updateZone(
        zoneUpdated,
        this.state.tourId,
        this.state.zoneId
      );

      console.log(
        "this.state.selectedZoneQrCodeUuid ",
        this.state.selectedZoneQrCodeUuid
      );

      console.log("response.data.uuid ", response.data.uuid);

      if (response.status === 201) {
        console.log("1");
        this.setState((prevState) => ({
          ...prevState,
          qrCodeDialogOpen: false,
          showError: false,
          successMessage: "Le QR Code à été mis à jours",
          showSuccess: true,
          formDatas: {
            ...prevState.formDatas,
            uuid: response.data.uuid,
          },
        }));
      } else if (response.data?.message === "zone-uuid-duplicate") {
        console.log("2");
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          qrCodeDialogOpen: false,
          errorMessage:
            "Cette zone à déjà été lié à une autre zone du même parcours",
          showSuccess: false,
        }));
      } else {
        console.log("2");
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          qrCodeDialogOpen: false,
          errorMessage: "Une erreur est survenue",
          showSuccess: false,
        }));
      }
    } else {
      console.log("3");
      this.setState((prevState) => ({
        ...prevState,
        qrCodeDialogOpen: false,
        showError: true,
        errorMessage: "Vous devez séléctionner une zone",
        showSuccess: false,
      }));
    }
  }

  async handleResetZoneLinked(event) {
    event.preventDefault();
    if (this.state.zoneId) {
      const response = await ApiZone.resetZoneLinkedUuid(
        this.state.tourId,
        this.state.zoneId
      );
      if (response.status === 200 && response.data) {
        console.log(
          "this.state.formDatas.defaultUuid ",
          this.state.formDatas.defaultUuid
        );
        this.setState((prevState) => ({
          ...prevState,
          qrCodeDialogOpen: false,
          showError: false,
          successMessage: `La zone n'est plus liée à une autre`,
          showSuccess: true,
          selectedZoneQrCodeUuid: null,
          formDatas: {
            ...prevState.formDatas,
            uuid: this.state.formDatas.defaultUuid,
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          qrCodeDialogOpen: false,
          showError: true,
          errorMessage: `Une erreur est survenue lors de la communication avec le serveur`,
          showSuccess: false,
        }));
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        qrCodeDialogOpen: false,
        showError: true,
        errorMessage: `Une erreur est survenue lors de la communication avec le serveur`,
        showSuccess: false,
      }));
    }
  }

  handleCloseCopyUuid(event) {
    this.setState((prevState) => ({
      ...prevState,
      qrCodeDialogOpen: false,
    }));
  }

  handleZoneModeChange(event, value) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        zoneMode: event.target.value,
      },
    }));
  }

  downloadQR = () => {
    const canvas = document.getElementById("zone_qr_code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = this.state.formDatas.name + "_QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  setForm(zoneDatas, tourId, zoneId) {
    this.setState((prevState) => ({
      ...prevState,
      zoneLoaded: true,
      editing: true,
      tourId: tourId,
      zoneId: zoneId,
      formDatas: {
        ...prevState.formDatas,
        id: zoneDatas.id,
        zoneMode: zoneDatas.zoneMode,
        name: zoneDatas.name,
        zoneRadius: zoneDatas.zoneRadius,
        timer: zoneDatas.timer,
        uuid: zoneDatas.uuid,
        defaultUuid: zoneDatas.defaultUuid,
        coords: {
          longitude: zoneDatas.coordsLongitude,
          latitude: zoneDatas.coordsLatitude,
        },
        isActive: zoneDatas.isActive,
        enigmasInRandomOrder: zoneDatas.enigmasInRandomOrder,
      },
    }));
  }

  updateName(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        name: event.target.value,
      },
    }));
  }

  updateZoneRadius(event, value) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        zoneRadius: parseInt(value),
      },
    }));
  }

  updateTimer(event, value) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        timer: parseInt(event.target.value),
      },
    }));
  }

  updateIsActive(event, value) {
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        isActive: value,
      },
    }));
  }

  updateEnigmasInRandomOrder(event) {
    const value = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        enigmasInRandomOrder: value,
      },
    }));
  }

  updateCoordsLon(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value))) return;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        coords: {
          ...prevState.formDatas.coords,
          longitude: parseFloat(event.target.value),
        },
      },
    }));
  }

  updateCoordsLat(event) {
    event.preventDefault();

    if (isNaN(parseFloat(event.target.value))) return;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        coords: {
          ...prevState.formDatas.coords,
          latitude: parseFloat(event.target.value),
        },
      },
    }));
  }

  async submitForm(event) {
    event.preventDefault();

    var serverResp = null;

    if (this.state.role === "NO_SUBSCRIBTION" || this.state.tourDisabled) {
      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      loadingViewOpened: true,
    }));
    if (this.state.editing) {
      serverResp = await ApiZone.updateZone(
        this.state.formDatas,
        this.state.tourId,
        this.state.zoneId
      );
      if (this.state.enigmaOrderIdChanged) {
        let res = await ApiEnigma.updateEnigmaOrdersIds(
          this.state.enigmasDatas
        );
      }
    } else {
      console.log("adding enigma");
      serverResp = await ApiZone.uploadZone(
        this.state.formDatas,
        this.state.tourId
      );
    }
    console.log("serverResp.data ", serverResp.data);

    this.setState((prevState) => ({
      ...prevState,
      loadingViewOpened: false,
    }));
    if (serverResp.status != 201 && serverResp.status != 200) {
      if (serverResp.status === 403) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: `Nombre maximum de zones atteint`,
          showSuccess: false,
        }));
        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: `Une erreur est survenue lors de la communication avec le serveur`,
        showSuccess: false,
      }));

      return;
    } else {
      console.log("serverResp.data ", serverResp.data);
      this.setState((prevState) => ({
        ...prevState,
        unsavedChanges: false,
        editing: true,
        showSuccess: true,
        successMessage: "La zone a bien été sauvegardée",
        showError: false,
        zoneId: serverResp.data.zoneId,
        formDatas: {
          ...prevState.formDatas,
          uuid: serverResp.data.uuid,
        },
      }));
    }
  }

  onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const enigmasDatas = this.state.enigmasDatas;

    const [enigmaRemoved] = enigmasDatas.splice(source.index, 1);
    enigmasDatas.splice(destination.index, 0, enigmaRemoved);

    enigmasDatas.forEach((enigma, id) => {
      enigma.orderId = id;
    });

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      enigmaOrderIdChanged: true,
      enigmasDatas: enigmasDatas,
    }));
  };

  async deleteEnigma(enigmaId) {
    let res = await ApiEnigma.deleteEnigma(enigmaId);

    if (res.status == 200) {
      let newEnigmas = this.state.enigmasDatas;
      newEnigmas.forEach((enigma, index) => {
        if (enigma.id == enigmaId) {
          newEnigmas.splice(index, 1);
        }
      });
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: true,
        successMessage: "L'énigme à bien été supprimé",
        showError: false,
        dialogWaringOpened: false,
        enigmasDatas: newEnigmas,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage:
          "Une erreur est survenue durant la suppréssion de l'énigma",
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  handleQrCodeBackgroundColorChanged(color) {
    this.setState((prevState) => ({
      ...prevState,
      qrCodeBgColor: color.hex.toString(),
    }));
  }

  handleQrCodeForegroundColorChanged(color) {
    this.setState((prevState) => ({
      ...prevState,
      qrCodeFgColor: color.hex.toString(),
    }));
  }

  openDeleteEnigmaDialog(enigmaId) {
    this.setState((prevState) => ({
      ...prevState,
      enigmaIdToDelete: enigmaId,
      dialogWaringOpened: true,
    }));
  }

  closeDeleteEnigmaDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  render() {
    const { classes } = this.props;

    let enigmasItems = [];

    if (this.state.enigmasDatas) {
      this.state.enigmasDatas.forEach((enigma) => {
        let item = {
          id: `enigma-${enigma.id}`,
          idInt: enigma.id,
          primary:
            enigma.title.length > 50
              ? enigma.title.substring(0, 50) + "..."
              : enigma.title,
          secondary: "",
          to: `/enigme?tourId=${this.state.tourId}&zoneId=${this.state.zoneId}&enigmaId=${enigma.id}`,
          icon: <CategoryIcon />,
          isValid: enigma.isValid,
        };

        enigmasItems.push(item);
      });
    }

    return (
      <>
        <NewZoneView
          {...this.props}
          state={this.state}
          submitForm={this.submitForm}
          enigmasItems={enigmasItems}
          updateIsActive={this.updateIsActive}
          updateName={this.updateName}
          handleZoneModeChange={this.handleZoneModeChange}
          updateZoneRadius={this.updateZoneRadius}
          updateCoordsLon={this.updateCoordsLon}
          updateCoordsLat={this.updateCoordsLat}
          zoneId={this.state.zoneId}
          downloadQR={this.downloadQR}
          handleQrCodeBackgroundColorChanged={
            this.handleQrCodeBackgroundColorChanged
          }
          handleQrCodeForegroundColorChanged={
            this.handleQrCodeForegroundColorChanged
          }
          updateTimer={this.updateTimer}
          onDragEnd={this.onDragEnd}
          openDeleteEnigmaDialog={this.openDeleteEnigmaDialog}
          deleteEnigma={this.deleteEnigma}
          closeDeleteEnigmaDialog={this.closeDeleteEnigmaDialog}
          tourLocationLat={this.state.tourLocationLat}
          tourLocationLng={this.state.tourLocationLng}
          showLatOnMap={this.state.showLatOnMap}
          showLngOnMap={this.state.showLngOnMap}
          setLocation={this.setLocation}
          mapDialogOpen={this.state.mapDialogOpen}
          closeMapDialog={this.closeMapDialog}
          openMapDialog={this.openMapDialog}
          handleValidateCopyUuid={this.handleValidateCopyUuid}
          handleCloseCopyUuid={this.handleCloseCopyUuid}
          handleChangeZoneQrCode={this.handleChangeZoneQrCode}
          handleChangeTourQrCode={this.handleChangeTourQrCode}
          openQrCodeDialog={this.openQrCodeDialog}
          handleResetZoneLinked={this.handleResetZoneLinked}
          updateEnigmasInRandomOrder={this.updateEnigmasInRandomOrder}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NewZone);
