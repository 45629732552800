import React, { useState } from "react";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  Icon,
  TableSortLabel,
  Grid,
  Card,
  CardMedia,
} from "@material-ui/core";
import {
  Search,
  Delete,
  Send,
  Cancel,
  BarChart,
  Fullscreen,
} from "@material-ui/icons";

import "moment/locale/fr";

const CodeListView = ({
  state,
  analytics,
  analyticsMonth,
  analyticsDay,
  tour,
  classes,
  tourId,
  totalZones,
  totalEnigmas,
  totalPoints,
  page,
  rowPerPage,
  formatDate,
  formatDateShort,
  formatDateHours,
  formatDateLong,
  handleChangePage,
  handleChangeRowsPerPage,
  setSortColumn,
  shouldShowTeamNameInput,
  updateTeamName,
  goFullScreen,
  exitFullScreen,
  forwardedRef,
}) => {
  return (
    <>
      {(!tourId || tourId === -1) && tourId !== 0 ? (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h6"
            style={{ fontWeight: 1000, color: "#768FDD", fontSize: "22px" }}
            gutterBottom
          >
            Parcours introuvable
          </Typography>
        </Box>
      ) : !state.leaderboardActivated ? (
        <></>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BarChart style={{ width: "40px", height: "40px" }}></BarChart>

            <Typography
              variant="h6"
              style={{
                fontWeight: 1000,
                color: "#151B2F",
                fontSize: "28px",
              }}
              gutterBottom
            >
              LEADERBOARD
            </Typography>
          </Box>
          {/* <button onClick={goFullScreen()}>Entrer en plein écran</button> */}
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <IconButton color="primary" onClick={goFullScreen}>
              <Fullscreen />
            </IconButton>
          </Box>

          <Box ref={forwardedRef} style={{ backgroundColor: "#F6F8FA" }}>
            <Box
              style={{
                display: "flex",
                direction: "row",
              }}
            >
              <Box style={{ width: "50px", height: "50px" }}>
                <img
                  className={classes.cardMedia}
                  alt="Description de l'image"
                  height="50"
                  width="50"
                  src="/logo192.png"
                  title="Titre de l'image"
                />
              </Box>
              <Box style={{ marginLeft: "16px", marginTop: "14px" }}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 600,
                    color: "#151B2F",
                    fontSize: "16px",
                  }}
                  gutterBottom
                >
                  {tour?.title}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {/* A gauche */}

                <Box>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 1000,
                      color: "#151B2F",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0px",
                    }}
                    className={classes.fontSizeAdaptativeTitle}
                    gutterBottom
                  >
                    Aujourd'hui
                  </Typography>
                  <TableContainer
                    component={Paper}
                    className={`${classes.tableLeft} ${classes.table}`}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                            style={{ width: 70 }}
                          >
                            Place
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                          >
                            Nom d'équipe
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                          >
                            Points
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                          >
                            Temps
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                          >
                            Heure
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {analyticsDay?.map((analytic, index) => (
                          <TableRow key={analytic.id}>
                            <TableCell
                              className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                            >
                              <div
                                className={`${
                                  index === 0
                                    ? classes.firstPlace
                                    : index === 1
                                    ? classes.scndPlace
                                    : index === 2
                                    ? classes.thrdPlace
                                    : classes.otherPlace
                                }`}
                              >
                                {index + 1}
                              </div>
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                            >
                              {!shouldShowTeamNameInput() ? (
                                analytic.teamNameOverride ? (
                                  analytic.teamNameOverride
                                ) : analytic.tourCode?.guestGameUser
                                    ?.teamName ? (
                                  analytic.tourCode?.guestGameUser?.teamName
                                ) : (
                                  "anonyme"
                                )
                              ) : (
                                <>
                                  <TextField
                                    required
                                    name="teamNameOverride"
                                    id="filled-required"
                                    defaultValue={
                                      analytic.teamNameOverride
                                        ? analytic.teamNameOverride
                                        : analytic.tourCode?.guestGameUser
                                            ?.teamName
                                    }
                                    label="Nom d'équipe"
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) =>
                                      updateTeamName(event, analytic.id)
                                    }
                                    className={classes.textField}
                                    style={{ backgroundColor: "white" }}
                                    inputProps={{ maxLength: 25 }}
                                    key={
                                      analytic.teamNameOverride
                                        ? "teamNameOverrideNotLoaded"
                                        : "teamNameOverrideLoaded"
                                    }
                                  />
                                </>
                              )}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                            >
                              {analytic.pointsWon ? analytic.pointsWon : "0"}/
                              {totalPoints}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                            >
                              {analytic.totalTime ? analytic.totalTime : "0"}{" "}
                              minutes
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                            >
                              {shouldShowTeamNameInput()
                                ? formatDateLong(analytic.game_ended_at)
                                : formatDateHours(analytic.game_ended_at)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* En haut a droite */}
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Box style={{ marginRight: "12px" }}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 1000,
                          color: "#151B2F",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0px",
                        }}
                        className={classes.fontSizeAdaptativeTitle}
                        gutterBottom
                      >
                        Ce mois-ci
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.tableRight}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                                style={{ width: 70 }}
                              >
                                Place
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                Nom d'équipe
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                Points
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                Temps
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {analyticsMonth?.map((analytic, index) => (
                              <TableRow key={analytic.id}>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  <div
                                    className={`${
                                      index === 0
                                        ? classes.firstPlace
                                        : index === 1
                                        ? classes.scndPlace
                                        : index === 2
                                        ? classes.thrdPlace
                                        : classes.otherPlace
                                    }`}
                                  >
                                    {index + 1}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {!shouldShowTeamNameInput() ? (
                                    analytic.teamNameOverride ? (
                                      analytic.teamNameOverride
                                    ) : analytic.tourCode?.guestGameUser
                                        ?.teamName ? (
                                      analytic.tourCode?.guestGameUser?.teamName
                                    ) : (
                                      "anonyme"
                                    )
                                  ) : (
                                    <>
                                      <TextField
                                        required
                                        name="teamNameOverride"
                                        id="filled-required"
                                        defaultValue={
                                          analytic.teamNameOverride
                                            ? analytic.teamNameOverride
                                            : analytic.tourCode?.guestGameUser
                                                ?.teamName
                                        }
                                        label="Nom d'équipe"
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) =>
                                          updateTeamName(event, analytic.id)
                                        }
                                        className={classes.textField}
                                        style={{ backgroundColor: "white" }}
                                        inputProps={{ maxLength: 25 }}
                                        key={
                                          analytic.teamNameOverride
                                            ? "teamNameOverrideNotLoaded"
                                            : "teamNameOverrideLoaded"
                                        }
                                      />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {analytic.pointsWon
                                    ? analytic.pointsWon
                                    : "0"}
                                  /{totalPoints}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {analytic.totalTime
                                    ? analytic.totalTime
                                    : "0"}{" "}
                                  minutes
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {shouldShowTeamNameInput()
                                    ? formatDateLong(analytic.game_ended_at)
                                    : formatDate(analytic.game_ended_at)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>

                  {/* En bas a droite */}
                  <Grid item>
                    <Box style={{ marginRight: "12px" }}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 1000,
                          color: "#151B2F",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className={classes.fontSizeAdaptativeTitle}
                        gutterBottom
                      >
                        Depuis le début
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.tableRight}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                                style={{ width: 70 }}
                              >
                                Place
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                Nom d'équipe
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                {shouldShowTeamNameInput() ? (
                                  <TableSortLabel
                                    className={classes.customSortLabel}
                                    active={state.sortColumn === "pointsWon"}
                                    direction={
                                      state.sortColumn === "pointsWon"
                                        ? "desc"
                                        : "asc"
                                    }
                                    onClick={
                                      shouldShowTeamNameInput()
                                        ? () => {
                                            setSortColumn("pointsWon");
                                          }
                                        : () => {}
                                    }
                                  ></TableSortLabel>
                                ) : (
                                  <></>
                                )}
                                Points
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                Temps
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellHeader} ${classes.fontSizeAdaptative}`}
                              >
                                {shouldShowTeamNameInput() ? (
                                  <TableSortLabel
                                    className={classes.customSortLabel}
                                    active={
                                      state.sortColumn === "game_ended_at"
                                    }
                                    direction={
                                      state.sortColumn === "game_ended_at"
                                        ? "desc"
                                        : "asc"
                                    }
                                    onClick={
                                      shouldShowTeamNameInput()
                                        ? () => {
                                            setSortColumn("game_ended_at");
                                          }
                                        : () => {}
                                    }
                                  ></TableSortLabel>
                                ) : (
                                  <></>
                                )}
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {analytics?.map((analytic, index) => (
                              <TableRow key={analytic.id}>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  <div
                                    className={`${
                                      index + page * rowPerPage === 0
                                        ? classes.firstPlace
                                        : index + page * rowPerPage === 1
                                        ? classes.scndPlace
                                        : index + page * rowPerPage === 2
                                        ? classes.thrdPlace
                                        : classes.otherPlace
                                    }`}
                                  >
                                    {index + page * rowPerPage + 1}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {!shouldShowTeamNameInput() ? (
                                    analytic.teamNameOverride ? (
                                      analytic.teamNameOverride
                                    ) : analytic.tourCode?.guestGameUser
                                        ?.teamName ? (
                                      analytic.tourCode?.guestGameUser?.teamName
                                    ) : (
                                      "anonyme"
                                    )
                                  ) : (
                                    <>
                                      <TextField
                                        required
                                        name="teamNameOverride"
                                        id="filled-required"
                                        defaultValue={
                                          analytic.teamNameOverride
                                            ? analytic.teamNameOverride
                                            : analytic.tourCode?.guestGameUser
                                                ?.teamName
                                        }
                                        label="Nom d'équipe"
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) =>
                                          updateTeamName(event, analytic.id)
                                        }
                                        className={classes.textField}
                                        style={{ backgroundColor: "white" }}
                                        inputProps={{ maxLength: 25 }}
                                        key={
                                          analytic.teamNameOverride
                                            ? "teamNameOverrideNotLoaded"
                                            : "teamNameOverrideLoaded"
                                        }
                                      />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {analytic.pointsWon
                                    ? analytic.pointsWon
                                    : "0"}
                                  /{totalPoints}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {analytic.totalTime
                                    ? analytic.totalTime
                                    : "0"}{" "}
                                  minutes
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.fontSizeAdaptative}`}
                                >
                                  {shouldShowTeamNameInput()
                                    ? formatDateLong(analytic.game_ended_at)
                                    : formatDate(analytic.game_ended_at)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          style={{ color: "white" }}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          component="div"
                          count={state.totalRows}
                          rowsPerPage={state.rowPerPage}
                          page={state.page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage="Lignes par page :"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} sur ${count}`
                          }
                        />
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              style={{
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                height: "12px",
                backgroundColor: "#F6F8FA",
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default CodeListView;
