import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

import AuthStorage from "../../../utils/authStorage";
import ApiUser from "../../../API/ApiUser";
import styles from "./loginStyles";
import LoginView from "./loginView";
import qs from "qs";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      islogged: false,
      loginParams: {
        user_email: "",
        user_password: "",
      },
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    document.title = "Connexion | Landing Zone";

    let verifyToken = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).verifyToken;
    if (verifyToken) {
      ApiUser.verifyAccount(verifyToken).then((response) => {
        if (response.status == 200) {
          this.setState({
            showSuccess: true,
            successMessage: "Votre compte a bien été validé",
          });
        }
      });
    }
  }
  //
  handleFormChange(event) {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;

    this.setState({
      loginParams: loginParamsNew,
    });
  }

  async login(event) {
    event.preventDefault();
    let user_email = this.state.loginParams.user_email;
    let user_password = this.state.loginParams.user_password;

    let response = await ApiUser.signin(user_email, user_password);

    if (response.status == 200) {
      this.setState({
        islogged: true,
      });
      const token = response.data.token;

      // Stocker le token (par exemple, dans le localStorage ou le state parent)
      localStorage.setItem("token", token);

      // Configurer l'en-tête Authorization pour les futures requêtes
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: "Mot de passe ou nom d'utilisateur incorrect",
        showError: true,
      }));
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.islogged) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <LoginView
          {...this.props}
          state={this.state}
          handleFormChange={this.handleFormChange}
          login={this.login}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Login));
