const styles = (theme) => ({
  formContainer: {
    paddingTop: "0px",
    paddingBottom: "30px",
    width: "60ch",
    margin: "auto",
    textAlign: "center",
  },
  card: {
    marginLeft: 32,
    height: 40,
    width: 40,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
    paddingBottom: 24,
    paddingTop: 24,
  },
  container: {
    marginTop: 50,
    padding: 30,
    borderRadius: 22,
  },
  title: {
    fontSize: 20,
    fontWeight: 800,
    marginBottom: 20,
    marginTop: 20,
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  subscriptionCard: {
    borderRadius: 20,
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#F1FAFD",
  },
  selectedPack: {},
  columnHeader: {
    backgroundColor: "#41616D",
    padding: 12,
  },
  periodic: {
    marginTop: -20,
    color: "#41616D",
    fontWeight: 600,
  },
  ht: {
    color: "#41616D",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center", // Aligne les éléments en haut (pour l'axe vertical)
    alignItems: "center",
    marginTop: 22,
  },
  row: {
    color: "#41616D",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start", // Aligne les éléments en haut (pour l'axe vertical)
    alignItems: "flex-start",
    width: "75%",
  },
  rowsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  subscribeButton: {
    backgroundColor: "#ED8621",
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
    padding: "10px 20px",
    borderRadius: 20,
    marginBottom: 0,
    marginTop: 30,
  },
  rowDivider: {
    backgroundColor: "#D9E7EC",
    height: "1px",
    width: "80%",
    marginTop: 12,
    marginBottom: 12,
  },
  badge: {
    display: "inline-block",
    padding: "2px 8px",
    backgroundColor: "#02F74C", // Couleur bleu
    color: "#000000", // Texte en blanc
    borderRadius: "12px", // Bords arrondis
    fontSize: "0.75rem", // Taille du texte petite
    fontWeight: "bold",
    position: "relative",
    top: "-5px", // Légèrement en hauteur
    height: 22,
    textAlign: "center",
  },
  containerPricesDiff: {
    display: "flex",
    alignItems: "center",
  },
  price: {
    fontSize: 60,
    fontWeight: "bold",
    color: "#3AC2F5",
    marginBottom: 0,
    paddingBottom: 0,
  },
  priceCrossed: {
    color: "white",
    fontSize: 24,
    color: "#41616D",
    marginBottom: 0,
    paddingBottom: 0,
    fontStyle: "italic",
    textDecoration: "line-through", // Barre le texte
    marginRight: "8px", // Ajoute un espace entre les deux Typography
  },
  weekFreeText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#41616D",
    marginBottom: 20,
  },
});

export default styles;
