import React from "react";
import { Fab, Tooltip, Zoom, Box, Typography } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

const FloatingVideoLink = ({ role }) => {
  return (
    <>
      {role === "NO_SUBSCRIBTION" && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
          }}
          style={{ color: "white" }}
        >
          <Tooltip
            title="Voir le tuto vidéo"
            TransitionComponent={Zoom}
            arrow
            placement="left"
          >
            <Fab
              variant="extended"
              href="https://www.youtube.com/watch?v=u5Y7IlfxlD8"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: "#F48A21",
              }}
              sx={{
                bgcolor: "#F48A21",
                color: "white",
                "&:hover": {
                  bgcolor: "primary.dark",
                },
              }}
            >
              <PlayCircleOutlineIcon
                sx={{ mr: 1 }}
                style={{ color: "white" }}
              />
              <Typography
                style={{ color: "white", textTransform: "none", marginLeft: 6 }}
              >
                Démo video
              </Typography>
            </Fab>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default FloatingVideoLink;
