import React from "react";

function SouthWestIcon() {
  return (
    <svg
      width="200px"
      height="200px"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="90" y="40" font-family="Arial" font-size="20" fill="black">
        N
      </text>

      <text x="90" y="180" font-family="Arial" font-size="20" fill="black">
        S
      </text>

      <text x="160" y="105" font-family="Arial" font-size="20" fill="black">
        E
      </text>

      <text x="20" y="105" font-family="Arial" font-size="20" fill="black">
        W
      </text>

      <circle
        cx="100"
        cy="100"
        r="80"
        stroke="black"
        stroke-width="2"
        fill="none"
      />

      <path
        d="M 100 50 Q 100 90, 80 120 L 70 130 L 90 160 L 110 130 L 100 120 Q 120 90, 120 50 A 50 50 0 1 1 100 50"
        fill="none"
        stroke="red"
        stroke-width="2"
      />

      <circle cx="100" cy="50" r="5" fill="red" />
    </svg>
  );
}

export default SouthWestIcon;
