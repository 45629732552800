import React from "react";
import { Link } from "react-router-dom";
import {
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  Divider,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import FileCopy from "@material-ui/icons/FileCopy";
import { Draggable } from "react-beautiful-dnd";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles({
  draggingListItem: {
    background: "#232C48",
  },
  listItem: {
    background: "#232C48",
    "&:hover": {
      backgroundColor: "#4B5679",
    },
  },
  listItemSelected: {
    background: "#4B5679",
    "&:hover": {
      backgroundColor: "#4B5679",
    },
  },
  deleteIcon: {
    color: "#DD7676",
  },
  icon: {
    color: "#768FDD",
  },
  iconAlert: {
    color: "#FF0000",
  },
  warningIcon: {
    marginLeft: "20px",
    height: "100%",
  },
  listItemText: {
    color: "#FFFFFF",
  },
  listItemTextTwo: {
    color: "#FFFFFF",
  },
  innactive: {
    backgroundColor: "#666975",
  },
});

const DraggableListItem = ({
  item,
  index,
  onDelete,
  onDuplicate,
  onEdit,
  onClick,
  selectedId,
}) => {
  const classes = useStyles();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={item.to} {...linkProps} />
      )),
    [item.to]
  );

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            item.isActive === false
              ? classes.innactive
              : snapshot.isDragging
              ? classes.draggingListItem
              : selectedId !== undefined && selectedId === item.idInt
              ? classes.listItemSelected
              : classes.listItem
          }
          button
          component={onClick ? null : CustomLink}
          onClick={onClick ? () => onClick(item.idInt) : null}
        >
          <ListItemAvatar>
            <Avatar>{item.icon}</Avatar>
          </ListItemAvatar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ListItemText
                primary={item.primary}
                secondary={item.secondary}
                classes={{
                  primary: classes.listItemText,
                  secondary: classes.listItemText,
                }}
              />
            </Grid>
            {item.primaryTwo && item.secondaryTwo ? (
              <Grid item xs={6}>
                <ListItemText
                  primary={item.primaryTwo}
                  secondary={item.secondaryTwo}
                  classes={{
                    primary: classes.listItemTextTwo,
                    secondary: classes.listItemTextTwo,
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={6} />
            )}
          </Grid>
          <ListItemSecondaryAction style={{ display: "flex" }}>
            {!item.isValid ? (
              <div>
                <Tooltip
                  className={classes.warningIcon}
                  title="Des données sont manquantes"
                >
                  <WarningRoundedIcon className={classes.iconAlert} />
                </Tooltip>
              </div>
            ) : (
              <div />
            )}
            {onEdit ? (
              <Tooltip title="Éditer">
                <IconButton
                  edge="end"
                  aria-label="edit"
                  className={classes.icon}
                  onClick={(e) => {
                    onEdit(item.idInt);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            ) : (
              <div />
            )}
            {onDuplicate ? (
              <Tooltip title="Dupliquer">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="white"
                  className={classes.icon}
                  onClick={(e) => {
                    onDuplicate(item.idInt);
                  }}
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            ) : (
              <div />
            )}
            {onDelete ? (
              <Tooltip title="Supprimer">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  className={classes.deleteIcon}
                  onClick={(e) => {
                    onDelete(item.idInt);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <div />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
