import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Button,
  Slider,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  CardMedia,
  IconButton,
  Box,
  Grid,
  FormControlLabel,
  Switch,
  Collapse,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";

import ImageUtils from "../../../utils/imageUtils";
import MainAppBar from "../../components/MainAppBar";
import LoadingView from "../../components/loadingView";
import BreadcrumbTour from "../../components/breadcrumTour";
import UnsavedChangesIndicator from "../../components/unsevedChangesIndicator";
import InfoPopover from "../../../utils/informationButton";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import { AddBox, Map } from "@material-ui/icons";
import SelectLocationDialog from "../../components/selectOnMapDialog";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import DisableToursPopover from "../../../utils/disableToursPopover";

const NewEnigmaView = (props) => {
  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }

  return (
    <>
      <MainAppBar
        headerText="Énigme"
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <Prompt
        when={props.state.unsavedChanges}
        message="Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?"
      />
      <NoSubscriptionBanner
        role={props.state.role}
        redirectToSelectedPack={null}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Container className={props.classes.formContainer} maxWidth={false}>
        <UnsavedChangesIndicator show={props.state.unsavedChanges} />
        <BreadcrumbTour
          tourId={props.state.formDatas.tourId}
          zoneId={props.state.formDatas.zoneId}
          enigmaId={props.state.formDatas.enigmaId}
          zoneMode={props.state.formDatas.zoneMode}
        />
        <br></br>
        <form
          className={props.classes.form}
          autoComplete="off"
          onSubmit={props.submitForm}
        >
          <Grid
            container
            spacing={3}
            direction="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={6}
              direction="column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box className={props.classes.textFieldContainer}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 10,
                  }}
                >
                  <Typography className={props.classes.headerTextField}>
                    Texte de l'énigme
                  </Typography>
                  <InfoPopover
                    title="Texte de l'énigme"
                    text="Il s'agit du texte qui sera affiché à l'utilisateur pour cette énigme. Il est possible d'utiliser des markdowns pour modifier l'affichage du texte dans l'application. Voici un exemple d'affichage avec ce texte : 
                    \nLien contact : [landingzone](https://landingzone.fr/contact/)
                    \n# Titre 1
                    \n## Titre 2
                    \n*Italique*
                    **Gras**
                    Émoji : ⚙️
                    ~~Barré~~
                    \n> Citation
                    "
                    isWhite={true}
                    src="/images/game-response-markdown.webp"
                  ></InfoPopover>
                </div>
                <TextField
                  required
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  defaultValue={props.state.formDatas.title}
                  onChange={props.updateTitle}
                  className={props.classes.textFieldBig}
                  multiline={true}
                  inputProps={{ maxLength: 800 }}
                  minRows={5}
                  key={
                    props.state.enigmaLoaded ? "titleNotLoaded" : "titleLoaded"
                  }
                />
              </Box>

              <Box className={props.classes.textFieldContainer}>
                <Typography className={props.classes.headerTextField}>
                  Nombre de points à gagner
                </Typography>

                <TextField
                  required
                  id="filled-required"
                  label=""
                  variant="outlined"
                  size="small"
                  type="number"
                  defaultValue={props.state.formDatas.points}
                  onChange={props.updatePoints}
                  className={props.classes.textField}
                  inputProps={{ maxLength: 1000 }}
                  key={
                    props.state.enigmaLoaded
                      ? "pointsNotLoaded"
                      : "pointsLoaded"
                  }
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              direction="column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box className={props.classes.textFieldContainerLight}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "60%",
                      backgroundColor: "white",
                      marginLeft: 8,
                      marginBottom: 8,
                    }}
                  >
                    <InputLabel id="select-response-type">
                      Type de réponse
                    </InputLabel>

                    <Select
                      labelId="select-response-type"
                      id="select-response-type"
                      value={props.state.formDatas.responseType}
                      onChange={props.handleSelectResponseTypeChange}
                    >
                      <MenuItem value={"text"}>Texte</MenuItem>
                      <MenuItem value={"picker"}>Choix multiple</MenuItem>
                      <MenuItem value={"compass"}>Boussole</MenuItem>
                      <MenuItem value={"switch"}>Switch</MenuItem>
                      <MenuItem value={"potentiometer"}>Potentiomètre</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                {props.state.formDatas.responseType == "text" ? (
                  <>
                    <InfoPopover
                      title="Réponse à texte"
                      text="Vous pouvez soit entrer directement la bonne réponse ou bien entrer plusieurs bonnes réponses en les séparant par des ';'. Dans ce dernier cas, toutes les réponses permettront de valider l'énigme. Les réponses ne sont pas sensibles à la casse. Dans l'exemple ci-dessous, les réponses 'Landing Zone' et 'Landing zone' sont également acceptées."
                      src="/images/reponse-text.webp"
                    ></InfoPopover>
                    <TextField
                      required
                      id="standard-helperText"
                      label="Réponse(s)"
                      variant="outlined"
                      size="small"
                      className={props.classes.textfieldResponses}
                      defaultValue={props.state.formDatas.responsesTextStr}
                      onChange={props.updateResponsesText}
                      minRows={2}
                      multiline={true}
                      key={
                        props.state.enigmaLoaded
                          ? "responsesNotLoaded"
                          : "responsesLoaded"
                      }
                    />
                    <Typography className={props.classes.helperText}>
                      Il est possible de rentrer plusieurs réponses possibles.
                      Pour cela séparez chaque réponse par un ';' (par exemple :
                      château;forteresse;manoir)
                    </Typography>
                  </>
                ) : props.state.formDatas.responseType == "picker" ? (
                  <>
                    <InfoPopover
                      title="Réponse à choix multiple"
                      text="Commencez par entrer la ou les bonnes réponses dans le premier champ. Séparez chaque réponse d'un ';' ce qui ajoutera un nouveau champ de texte. Ces champs de textes correspondent aux colonnes de choix dans l'application. Pour chaque nouveau champ, vous devez ajouter des propositions en les séparant également par des ';'. Assurez-vous que chaque réponse est également présente parmi les propositions fournies."
                      src="/images/reponse-choix-mul.png"
                    ></InfoPopover>
                    <TextField
                      required
                      id="standard-helperText"
                      label="Réponse à choix multiple"
                      variant="outlined"
                      size="small"
                      minRows={2}
                      multiline={true}
                      className={props.classes.textfieldResponses}
                      defaultValue={props.state.formDatas.responsesPickerStr}
                      onChange={props.handleResponsesPickerTextChange}
                      key={
                        props.state.enigmaLoaded
                          ? "choicesRespnonsesNotLoaded"
                          : "choicesResponsesLoaded"
                      }
                    />
                    <Typography className={props.classes.helperText}>
                      Veuillez séparer chaque réponse par un ';' (par exemple :
                      feu;terre;vent). Assurez-vous que chaque réponse est
                      également présente parmi les propositions fournies
                    </Typography>
                  </>
                ) : props.state.formDatas.responseType == "compass" ? (
                  <>
                    <InfoPopover
                      title="Réponse avec boussole"
                      text="Vous devez entrer un cap compris entre 0° et 360°. L'utilisateur devra pointer son téléphone dans la bonne direction puis valider. Une marge d'erreur de 40° est comprise. Si vous rentrez 180°, les valeurs admises iront de 160° à 200°."
                      src="/images/reponse-boussole.webp"
                    ></InfoPopover>
                    <TextField
                      required
                      id="standard-helperText"
                      label="Orientation"
                      variant="outlined"
                      type="number"
                      size="small"
                      inputProps={{
                        min: 0,
                        max: 360,
                      }}
                      minRows={2}
                      className={props.classes.textfieldResponses}
                      defaultValue={props.state.formDatas.compass}
                      onChange={props.handleResponsesCompassChange}
                      key={
                        props.state.enigmaLoaded
                          ? "compassNotLoaded"
                          : "compassLoaded"
                      }
                    />
                  </>
                ) : props.state.formDatas.responseType == "switch" ? (
                  <>
                    <InfoPopover
                      title="Réponse avec bouton on/off"
                      text="Entrez un label pour le bouton ou bien laissez vide. Vous pouvez ensuite cocher ou décocher le bouton."
                      src="/images/reponse-switch.webp"
                    ></InfoPopover>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        className={props.classes.uploadFile}
                        startIcon={<AddBox />}
                        component="label"
                        onClick={props.addSwitch}
                      >
                        Ajouter un switch
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <InfoPopover
                      title="Réponse avec potentiomètre"
                      text="Entrez un label pour le potentiomètre ou bien laissez vide. Vous pouvez ensuite ajouter une valeur comprise entre 0 et 9"
                      src="/images/reponse-pot.webp"
                    ></InfoPopover>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        className={props.classes.uploadFile}
                        startIcon={<AddBox />}
                        component="label"
                        onClick={props.addPotentiometer}
                      >
                        Ajouter un potentiomètre
                      </Button>
                    </Box>
                  </>
                )}
                <br />
                <br />

                <Box
                  direction="column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {props.state.formDatas.responseType == "picker" ? (
                    props.state.formDatas?.responsesPicker?.map(
                      (response, index) => {
                        let pickerId = `picker-response-${index.toString()}`;
                        let pikerLabel = `Propositions pour la réponse ${response}`;

                        return (
                          <>
                            <TextField
                              required
                              id={pickerId}
                              label={pikerLabel}
                              variant="outlined"
                              size="small"
                              multiline={true}
                              inputProps={{ maxLength: 355 }}
                              className={props.classes.textfieldPropositions}
                              onChange={(e) =>
                                props.updatePickerChoice(e, index)
                              }
                              defaultValue={
                                props.state.formDatas.pickerChoices
                                  ? props.state.formDatas.pickerChoices.length >
                                    index
                                    ? props.state.formDatas.pickerChoices[index]
                                        .value
                                    : ""
                                  : ""
                              }
                              key={
                                props.state.enigmaLoaded
                                  ? `${pickerId}NotLoaded`
                                  : `${pickerId}enigmaLoaded`
                              }
                            />
                            <Typography
                              className={props.classes.helperTextSmall}
                            >
                              Veuillez séparer chaque proposition d'un ';'
                            </Typography>
                          </>
                        );
                      }
                    )
                  ) : props.state.formDatas.responseType == "switch" ? (
                    props.state.formDatas?.switchLabels?.map((label, index) => {
                      let switchId = `switch-label-${index.toString()}`;
                      let switchLabel = `Label ${index} (optionnel)`;

                      return (
                        <div
                          key={
                            props.state.enigmaLoaded
                              ? `${switchId}SwitchNotLoaded`
                              : `${switchId}SwitchenigmaLoaded`
                          }
                        >
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",
                              flexDirection: "row",
                            }}
                          >
                            <TextField
                              id={switchId}
                              label={switchLabel}
                              variant="outlined"
                              size="small"
                              className={props.classes.textfieldPropositions}
                              onChange={(event) =>
                                props.handleSwitchLabelChange(event, index)
                              }
                              type="text"
                              value={label}
                              key={
                                props.state.enigmaLoaded
                                  ? `${switchId}NotLoaded`
                                  : `${switchId}enigmaLoaded`
                              }
                            />
                            <FormControlLabel
                              key={
                                props.state.enigmaLoaded
                                  ? `${switchId}ControlNotLoaded`
                                  : `${switchId}ControlenigmaLoaded`
                              }
                              control={
                                <Checkbox
                                  key={
                                    props.state.enigmaLoaded
                                      ? `${switchId}CheckboxNotLoaded`
                                      : `${switchId}CheckboxenigmaLoaded`
                                  }
                                  checked={
                                    props.state.formDatas.switchValues
                                      ? props.state.formDatas.switchValues
                                          .length > index
                                        ? props.state.formDatas.switchValues[
                                            index
                                          ]
                                        : ""
                                      : ""
                                  }
                                  onChange={(event) =>
                                    props.handleSwitchValueChange(event, index)
                                  }
                                  color="primary"
                                />
                              }
                              label="ON"
                            />
                            {index != 0 ? (
                              <IconButton
                                key={
                                  props.state.enigmaLoaded
                                    ? `${switchId}RemoveBtnNotLoaded`
                                    : `${switchId}RemoveBtnControlenigmaLoaded`
                                }
                                edge="end"
                                aria-label="delete"
                                onClick={(e) => {
                                  props.removeSwitch(e, index);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            ) : (
                              <Box style={{ width: "35px" }}></Box>
                            )}
                          </Box>
                        </div>
                      );
                    })
                  ) : props.state.formDatas.responseType == "potentiometer" ? (
                    props.state.formDatas?.potentiometerLabels?.map(
                      (label, index) => {
                        let potentiometerId = `potentiometer-label-${index.toString()}`;
                        let potentiometerLabel = `Label ${index} (optionnel)`;

                        return (
                          <div
                            key={
                              props.state.enigmaLoaded
                                ? `${potentiometerId}potentiometerNotLoaded`
                                : `${potentiometerId}potentiometerEnigmaLoaded`
                            }
                          >
                            <Box
                              direction="row"
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "start",
                                flexDirection: "row",
                              }}
                            >
                              <TextField
                                id={potentiometerId}
                                label={potentiometerLabel}
                                variant="outlined"
                                size="small"
                                className={props.classes.textfieldPropositions}
                                onChange={(event) =>
                                  props.handlePotentiometerLabelChange(
                                    event,
                                    index
                                  )
                                }
                                type="text"
                                value={label}
                                key={
                                  props.state.enigmaLoaded
                                    ? `${potentiometerId}NotLoaded`
                                    : `${potentiometerId}enigmaLoaded`
                                }
                              />

                              <TextField
                                id={`${potentiometerId}-value`}
                                label="Valeur"
                                type="number"
                                inputProps={{
                                  min: 0,
                                  max: 10,
                                }}
                                variant="outlined"
                                size="small"
                                className={props.classes.textfieldPropositions}
                                style={{ minWidth: "80px" }}
                                onChange={(event) =>
                                  props.handlePotentiometerValueChange(
                                    event,
                                    index
                                  )
                                }
                                value={
                                  props.state.formDatas.potentiometerValues
                                    ? props.state.formDatas.potentiometerValues
                                        .length > index
                                      ? props.state.formDatas
                                          .potentiometerValues[index]
                                      : ""
                                    : ""
                                }
                                key={
                                  props.state.enigmaLoaded
                                    ? `${potentiometerId}ValueNotLoaded`
                                    : `${potentiometerId}ValueenigmaLoaded`
                                }
                              />

                              {index != 0 ? (
                                <IconButton
                                  key={
                                    props.state.enigmaLoaded
                                      ? `${potentiometerId}RemoveBtnNotLoaded`
                                      : `${potentiometerId}RemoveBtnControlenigmaLoaded`
                                  }
                                  edge="end"
                                  aria-label="delete"
                                  onClick={(e) => {
                                    props.removePotentiometer(e, index);
                                  }}
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                              ) : (
                                <Box style={{ width: "35px" }}></Box>
                              )}
                            </Box>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <br />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <br />
          <Divider />
          <br />

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Typography
              style={{ fontSize: 24, fontWeight: 800, color: "#48506B" }}
              gutterBottom
            >
              OPTIONNEL
            </Typography>
          </Box>

          <Grid
            container
            spacing={3}
            direction="column"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              margin: 0,
            }}
          >
            <Grid
              item
              direction="column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                width: "100%",
                height: "100%",
                backgroundColor: "#DCE0EC",
                borderRadius: 12,
              }}
            >
              <Typography
                className={props.classes.primaryOptionalTitle}
                align="inherit"
                gutterBottom
              >
                Média
              </Typography>
              <Grid
                item
                xs={12}
                direction="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  direction="column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box className={props.classes.boxOptional}>
                    <Typography
                      className={props.classes.optionalTitle}
                      align="inherit"
                      gutterBottom
                    >
                      IMAGE DE L'ÉNIGME
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        className={props.classes.uploadFile}
                        startIcon={<CloudUploadIcon />}
                        component="label"
                      >
                        Image
                        <input
                          type="file"
                          onChange={props.vintageImageSelected}
                          hidden
                          accept=".png,.jpg,.jpeg"
                        />
                      </Button>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          props.deleteImage(false, true, false);
                        }}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </Box>
                    <Typography
                      style={{ marginTop: 6 }}
                      className={props.classes.imageDimensionsText}
                      align="inherit"
                      gutterBottom
                    >
                      Taille maximum: 2mo
                    </Typography>
                    <Typography
                      className={props.classes.imageDimensionsText}
                      gutterBottom
                    >
                      Dimensions recommandées (téléphone): 411x482px
                      <br />
                      Dimensions recommandées (tablette): 1024x998px
                    </Typography>
                    {props.state.showVintageImageTooBig ? (
                      <Typography
                        className={props.classes.imageDimensionsText}
                        style={{ color: "red" }}
                        gutterBottom
                      >
                        L'image ne doit pas éxcéder 2mo
                      </Typography>
                    ) : (
                      <div />
                    )}
                    <Grid
                      item
                      xs={12}
                      direction="column"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                        width: "100%",
                      }}
                    >
                      <Container>
                        <Typography
                          className={props.classes.imageInfos}
                          gutterBottom
                        >
                          Affichage sur téléphone
                        </Typography>
                        <Card className={props.classes.imageCardVintage}>
                          <CardMedia
                            className={props.classes.imageCardMedia}
                            image={
                              ImageUtils.getImageUrlFromFile(
                                props.state.formDatas.vintage?.imageFile
                              )
                                ? ImageUtils.getImageUrlFromFile(
                                    props.state.formDatas.vintage?.imageFile
                                  )
                                : props.state.formDatas.vintage?.imageUrl
                            }
                            title="VintageImage"
                          />
                        </Card>
                      </Container>
                      <Container>
                        <Typography
                          className={props.classes.imageInfos}
                          gutterBottom
                        >
                          Affichage sur tablette
                        </Typography>
                        <Card className={props.classes.imageCardVintageTab}>
                          <CardMedia
                            className={props.classes.imageCardMedia}
                            image={
                              ImageUtils.getImageUrlFromFile(
                                props.state.formDatas.vintage?.imageFile
                              )
                                ? ImageUtils.getImageUrlFromFile(
                                    props.state.formDatas.vintage?.imageFile
                                  )
                                : props.state.formDatas.vintage?.imageUrl
                            }
                            title="VintageImage"
                          />
                        </Card>
                      </Container>
                    </Grid>
                  </Box>
                  <br />
                  {/* <Box className={props.classes.spaceBetweenGrids}></Box> */}
                </Grid>{" "}
                <br />
                <Box className={props.classes.hrVertical}></Box>
                <Typography
                  className={props.classes.orTitle}
                  align="inherit"
                  gutterBottom
                >
                  OU
                </Typography>
                <br />
                <br />
                <Box
                  className={`${props.classes.boxOptional}`}
                  style={{ paddingBottom: 29 }}
                >
                  <div>
                    <Typography
                      className={props.classes.warningBeta}
                      align="inherit"
                    >
                      Cette fonctionnalité est en version bêta et peut ne pas
                      fonctionner sur tous les téléphones.
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    <Typography
                      className={props.classes.optionalTitle}
                      align="inherit"
                      gutterBottom
                    >
                      OBJET 3D DE L'ÉNIGME
                    </Typography>
                    <div className={props.classes.beta}>Bêta</div>
                  </div>

                  <InfoPopover
                    title="Réalité augmentée"
                    text="Il y a deux modes pour la réalité augmentée.
                  \nLe premier affichera l'objet 3D devant l'utilisateur.
                  \nLe deuxième affichera l'objet 3D sur l'image si vous en ajoutez une ci-dessous.
                  \nLorsque vous ajoutez un objet 3D vous devez :
                  \n 1. Ne pas compresser le fichier GLB.
                  \n 2. Tester l'affichage sur l'application sur IOS et Android.
                  \nLes images doivent respecter certaines conditions pour que la détection fonctionne correctement : 
                  \n 1. La résolution de l'image doit être d'au moins 300 x 300 px.
                  \n 2. Évitez les images contenant très peu de caractéristiques géométriques (par exemple, des codes-barres, des codes QR et des logos).
                  \n 3. Évitez les images présentant des motifs répétitifs.
                  \n 4. L'image à scanner doit être sur une surface plane."
                    src="/images/media-ar.webp"
                  ></InfoPopover>

                  <Grid direction="row">
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          className={props.classes.uploadFile}
                          startIcon={<CloudUploadIcon />}
                          component="label"
                        >
                          Fichier GLB
                          <input
                            type="file"
                            onChange={props.arObjectSelected}
                            hidden
                            accept=".glb"
                          />
                        </Button>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => {
                            props.deleteObjFile();
                          }}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                      </Box>
                      <Typography
                        style={{ marginTop: 6, alignSelf: "center" }}
                        className={props.classes.imageDimensionsText}
                        align="inherit"
                        gutterBottom
                      >
                        Taille maximum: 8mo
                      </Typography>
                      {props.state.showArObjectTooBig ? (
                        <Typography
                          className={props.classes.imageDimensionsText}
                          style={{ color: "red", alignSelf: "center" }}
                          gutterBottom
                        >
                          Le fichier ne doit pas éxcéder 8mo
                        </Typography>
                      ) : (
                        <div />
                      )}
                      {props.state.formDatas.arObject?.file != null ? (
                        <>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography className={props.classes.filename}>
                              {props.state.formDatas.arObject.file.name}
                            </Typography>
                          </Box>
                        </>
                      ) : props.state.formDatas.arObject?.fileName != null ? (
                        <>
                          {" "}
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography className={props.classes.filename}>
                              {props.state.formDatas.arObject?.fileName}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}

                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          className={props.classes.uploadFile}
                          startIcon={<CloudUploadIcon />}
                          component="label"
                        >
                          Image à reconnaître
                          <input
                            type="file"
                            onChange={props.arImageRecognitionSelected}
                            hidden
                            accept=".png,.jpg,.jpeg"
                          />
                        </Button>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => {
                            props.deleteImage(false, false, true);
                          }}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                      </Box>
                      <Typography
                        style={{ marginTop: 6, alignSelf: "center" }}
                        className={props.classes.imageDimensionsText}
                        align="inherit"
                        gutterBottom
                      >
                        Taille maximum: 2mo
                      </Typography>
                      {props.state.showArRecognitionImageTooBig ? (
                        <Typography
                          className={props.classes.imageDimensionsText}
                          style={{ color: "red" }}
                          gutterBottom
                        >
                          L'image ne doit pas éxcéder 2mo
                        </Typography>
                      ) : (
                        <div />
                      )}
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#E5E8EB",
                          borderRadius: 8,
                          marginTop: "12px",
                        }}
                      >
                        <img
                          className={props.classes.imageArRecognition}
                          src={
                            ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.arImageRecognition
                                ?.imageFile
                            )
                              ? ImageUtils.getImageUrlFromFile(
                                  props.state.formDatas.arImageRecognition
                                    ?.imageFile
                                )
                              : props.state.formDatas.arImageRecognition
                                  ?.imageUrl
                          }
                        ></img>
                      </Box>
                      {/* AR avec localisation (pas disponible sur téléphone pour le moment) */}
                      {/* {props.state.formDatas.zoneMode === 0 && (
                      <>
                        <br />
                        <Typography className={props.classes.headerLocations}>
                          Position de l'objet dans la zone (optionnel)
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            label="Latitude"
                            onChange={props.updateArCoordsLatitude}
                            value={
                              props.state.formDatas.coordsLatitudeArObject !== 0
                                ? props.state.formDatas.coordsLatitudeArObject
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            variant="outlined"
                            size="small"
                            style={{ width: "100px" }}
                            inputProps={{
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.enigmaLoaded
                                ? "latCoordsNotLoaded"
                                : "latCoordsLoaded"
                            }
                          />
                          <TextField
                            label="Longitude"
                            onChange={props.updateArCoordsLongitude}
                            value={
                              props.state.formDatas.coordsLongitudeArObject !==
                              0
                                ? props.state.formDatas.coordsLongitudeArObject
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            style={{ width: "100px" }}
                            variant="outlined"
                            size="small"
                            inputProps={{
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.enigmaLoaded
                                ? "lonCoordsNotLoaded"
                                : "lonCoordsLoaded"
                            }
                          />
                          <Button
                            onClick={() => props.openMapDialog()}
                            className={props.classes.mapButton}
                            startIcon={<Map />}
                          >
                            Sélectionner
                          </Button>
                        </Box>
                      </>
                    )} */}
                    </Box>
                  </Grid>
                </Box>
                <Box className={props.classes.hrVertical}></Box>
                <Typography
                  className={props.classes.orTitle}
                  align="inherit"
                  gutterBottom
                >
                  OU
                </Typography>
                <br />
                <br />
                <Box className={`${props.classes.boxOptional}`}>
                  <Typography
                    className={props.classes.optionalTitle}
                    align="inherit"
                    gutterBottom
                  >
                    VIDÉO DE L'ÉNIGME
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <TextField
                      id="filled-required"
                      label="Url vers une vidéo Youtube"
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      size="small"
                      className={props.classes.textfieldResponses}
                      multiline={true}
                      value={
                        props.hasOtherMediaThanYT()
                          ? ""
                          : props.state.formDatas.youtubeVideoUrl
                      }
                      onChange={props.updateYoutubeVideoUrl}
                      key={
                        props.state.enigmaLoaded ? "YTLoaded" : "YTNotLoaded"
                      }
                    />
                  </Box>
                </Box>
                <br />
                <br />
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              item
              direction="column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                width: "100%",
                height: "100%",
                backgroundColor: "#DCE0EC",
                borderRadius: 12,
              }}
            >
              <Typography
                className={props.classes.primaryOptionalTitle}
                align="inherit"
                gutterBottom
              >
                Infos
              </Typography>
              <Grid
                item
                xs={12}
                direction="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  width: "100%",
                }}
              >
                <Box
                  className={`${props.classes.boxOptional}`}
                  style={{ marginRight: 12 }}
                >
                  <Typography
                    className={props.classes.optionalTitle}
                    align="inherit"
                    gutterBottom
                  >
                    INDICE
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={props.classes.uploadFile}
                      startIcon={<CloudUploadIcon />}
                      component="label"
                    >
                      Image de l'indice
                      <input
                        type="file"
                        onChange={props.clueImageSelected}
                        hidden
                        accept=".png,.jpg,.jpeg"
                      />
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        props.deleteImage(true, false, false);
                      }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  </Box>

                  <Typography
                    style={{ marginTop: 6 }}
                    className={props.classes.imageDimensionsText}
                    align="inherit"
                    gutterBottom
                  >
                    Taille maximum: 2mo
                  </Typography>
                  <Typography
                    className={props.classes.imageDimensionsText}
                    gutterBottom
                  >
                    Dimensions recommandées (téléphone): 410x700px
                    <br />
                    Dimensions recommandées (tablette): 1152x1308px
                  </Typography>
                  {props.state.showVintageImageTooBig ? (
                    <Typography
                      className={props.classes.imageDimensionsText}
                      style={{ color: "red" }}
                      gutterBottom
                    >
                      Le fichier ne doit pas éxcéder 2mo
                    </Typography>
                  ) : (
                    <div />
                  )}
                  <Grid
                    item
                    xs={12}
                    direction="row"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      width: "100%",
                    }}
                  >
                    <Container>
                      <Typography
                        className={props.classes.imageInfos}
                        gutterBottom
                      >
                        Affichage sur téléphone
                      </Typography>
                      <Card className={props.classes.imageCardClue}>
                        <div
                          style={{
                            backgroundImage: `url(${
                              ImageUtils.getImageUrlFromFile(
                                props.state.formDatas.clue?.imageFile
                              ) || props.state.formDatas.clue?.imageUrl
                            })`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#E5E8EB",
                          }}
                          title="ClueImage"
                        />
                      </Card>
                    </Container>
                    <Container>
                      <Typography
                        className={props.classes.imageInfos}
                        gutterBottom
                      >
                        Affichage sur tablette
                      </Typography>
                      <Card className={props.classes.imageCardClueTab}>
                        <div
                          style={{
                            backgroundImage: `url(${
                              ImageUtils.getImageUrlFromFile(
                                props.state.formDatas.clue?.imageFile
                              ) || props.state.formDatas.clue?.imageUrl
                            })`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#E5E8EB",
                          }}
                          title="ClueImage"
                        />
                      </Card>
                    </Container>
                  </Grid>

                  <br />

                  <Typography
                    id="discrete-slider"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Temps avant que l'indice soit disponnible
                  </Typography>
                  <Slider
                    onChange={props.updateClueTime}
                    value={props.state.formDatas.clue?.timer}
                    aria-labelledby="discrete-slider"
                    valueLabelFormat={(value) => `${value} minutes`}
                    valueLabelDisplay="on"
                    ValueLabelComponent={ValueLabelComponent}
                    step={1}
                    marks
                    min={0}
                    max={30}
                    style={{ width: "90%" }}
                    key={
                      props.state.enigmaLoaded
                        ? "timerClueNotLoaded"
                        : "timerClueLoaded"
                    }
                  />

                  <br />
                  <br />
                  <InfoPopover
                    title="Texte de l'indice"
                    text="Il s'agit du texte qui sera affiché à l'utilisateur pour cette indice. Il est possible d'utiliser des markdowns pour modifier l'affichage du texte dans l'application. Voici un exemple d'affichage avec ce texte : 
                    \nLien contact : [landingzone](https://landingzone.fr/contact/)
                    \n# Titre 1
                    \n## Titre 2
                    \n*Italique*
                    **Gras**
                    Émoji : ⚙️
                    ~~Barré~~
                    \n> Citation
                    "
                    src="/images/game-clue-markdown.webp"
                  ></InfoPopover>
                  <TextField
                    id="filled-required"
                    label="Texte de l'indice"
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    size="small"
                    multiline={true}
                    minRows={4}
                    defaultValue={props.state.formDatas.clue?.title}
                    className={props.classes.textfieldResponses}
                    onChange={props.updateClueTitle}
                    key={
                      props.state.enigmaLoaded
                        ? "clueTitleLoaded"
                        : "clueTitleNotLoaded"
                    }
                  />
                </Box>
                <br />
                <br />
                {/* <Box className={`${props.classes.boxOptionalRight}`}>
                <Typography
                  className={props.classes.optionalTitle}
                  align="inherit"
                  gutterBottom
                >
                  SON
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                </Box>
              </Box> */}
                <Box className={props.classes.hrVertical}></Box>

                <Grid
                  item
                  direction="column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 12,
                  }}
                >
                  <Box className={props.classes.boxOptional}>
                    <Typography
                      className={props.classes.optionalTitle}
                      align="inherit"
                      gutterBottom
                    >
                      TEXTE DE FIN D'ÉNIGME
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 10,
                      }}
                    >
                      <FormControl
                        className={props.classes.formControl}
                        style={{ marginTop: 14 }}
                      >
                        <InputLabel
                          variant="outlined"
                          id="select-response-type"
                        >
                          Affichage du texte
                        </InputLabel>
                        <Select
                          labelId="select-response-type"
                          id="select-response-type"
                          value={props.state.formDatas.showFinishTextOnLoose}
                          onChange={props.updateShowFinishOnLoose}
                        >
                          <MenuItem value={true}>
                            En cas de bonne et de mauvaise réponse
                          </MenuItem>
                          <MenuItem value={false}>
                            Uniquement en cas de bonne réponse
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <InfoPopover
                        title="Texte de fin d'énigme"
                        text="Il s'agit du texte qui sera affiché à l'utilisateur après cette énigme. Il est possible d'afficher un texte personnalisé soit uniquement en cas de bonne réponse, soit en cas de bonne et de mauvaise réponse. Vous pouvez utiliser des markdowns pour modifier l'affichage du texte dans l'application. Voici un exemple d'affichage avec ce texte : 
                      \nLien contact : [landingzone](https://landingzone.fr/contact/)
                      \n# Titre 1
                    \n## Titre 2
                    \n*Italique*
                    **Gras**
                    Émoji : ⚙️
                    ~~Barré~~
                    \n> Citation
                    "
                        src="/images/game-response-popup-markdown.webp"
                      ></InfoPopover>
                    </div>
                    <TextField
                      id="filled-required"
                      label="Texte à afficher à la fin de l'énigme"
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      size="small"
                      minRows={4}
                      className={props.classes.textfieldResponses}
                      multiline={true}
                      defaultValue={props.state.formDatas.finishText}
                      onChange={props.updateFinishText}
                      key={
                        props.state.enigmaLoaded
                          ? "finishTextLoaded"
                          : "finishTextNotLoaded"
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br />
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
          <br />
          <div ref={props.state.endOfPageRef} />
          <LoadingView
            className={props.classes.loadingView}
            open={props.state.loadingViewOpened}
          />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Link
              className={props.classes.returnButtonLink}
              to={`/zone?tourId=${props.state.formDatas.tourId}&zoneId=${props.state.formDatas.zoneId}`}
            >
              <Button variant="outlined" color="darkGrey">
                RETOUR
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={`${props.classes.saveButton} ${
                props.state.role === "NO_SUBSCRIBTION" ||
                props.state.tourDisabled
                  ? props.classes.buttonDisabled
                  : ""
              }`}
              type="submit"
            >
              {props.state.editing ? (
                <div>ENREGISTRER</div>
              ) : (
                <div>AJOUTER</div>
              )}
            </Button>
          </Box>
        </form>
      </Container>
      {/* AR avec localisation (pas disponible sur téléphone pour le moment) */}
      {/* <SelectLocationDialog
        type="AR_OBJECT"
        lat={props.showLatOnMap}
        lng={props.showLngOnMap}
        open={props.mapDialogOpen}
        zoneLng={props.state.zoneLng}
        zoneLat={props.state.zoneLat}
        zoneRadius={props.state.zoneRadius}
        initLocationLat={
          props.state.formDatas.coordsLatitudeArObject
            ? props.state.formDatas.coordsLatitudeArObject
            : props.state.currentZone?.coordsLatitude
        }
        initLocationLng={
          props.state.formDatas.coordsLongitudeArObject
            ? props.state.formDatas.coordsLongitudeArObject
            : props.state.currentZone?.coordsLongitude
        }
        onClose={() => props.closeMapDialog()}
        onLocationSelected={(location, type) => {
          props.setArObjectLocation(location);
        }}
      />{" "} */}
    </>
  );
};

export default NewEnigmaView;
