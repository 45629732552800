import React, { useState, useRef } from "react";
import * as moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/Map";
import qs from "qs";

import styles from "./analyticsListStyles";
import AnalyticsListView from "./analyticsListView";
import ApiTour from "../../../../API/ApiTour";
import ApiUser from "../../../../API/ApiUser";
import ApiGameAnalytics from "../../../../API/ApiGameAnalytics";
import ApiAdmin from "../../../../API/ApiAdmin";

moment.locale("fr");

class AnalyticsListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      analytics: [],
      analyticsMonth: [],
      analyticsDay: [],
      page: 0,
      sortColumn: "pointsWon",
      rowPerPage: 20,
      totalRows: 0,
      totalRowsMonth: 0,
      totalRowsDay: 0,
      totalZones: 0,
      totalEnigmas: 0,
      totalPoints: 0,
      tourId: -1,
      tour: null,
      isAdmin: false,
      isUserTour: false,
    };

    this.formatDate = this.formatDate.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.shouldShowTeamNameInput = this.shouldShowTeamNameInput.bind(this);
    this.setSortColumn = this.setSortColumn.bind(this);
    this.goFullScreen = this.goFullScreen.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);

    this.updateTeamName = this.updateTeamName.bind(this);

    this.ref = React.createRef();
  }

  componentDidMount() {
    let tourId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).tourId;
    let tourIdInt = parseInt(tourId);

    this.setState(
      (prevState) => ({
        ...prevState,
        tourId: tourIdInt,
      }),
      () => {}
    );

    this.timerID = setInterval(
      () => {
        console.log("get analytics");
        this.getAnalytics(tourIdInt);
      },
      60000 // 60000 millisecondes = 1 minute
    );

    this.getAnalytics(tourIdInt);
    this.getTour(tourId);
    this.setStateIsAdmin();
  }

  componentWillUnmount() {
    // Nettoie le timer lors du démontage du composant
    clearInterval(this.timerID);
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  goFullScreen = () => {
    console.log("a");
    console.log("this.ref ", this.ref);
    if (this.ref.current) {
      console.log("b");
      this.ref.current.requestFullscreen().catch((err) => {
        alert(
          `Erreur lors de la tentative de passage en plein écran: ${err.message} (${err.name})`
        );
      });
    }
  };

  exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        alert(
          `Erreur lors de la tentative de sortie du plein écran: ${err.message} (${err.name})`
        );
      });
    }
  };

  updateTeamName(event, analyticId) {
    let target = event.target;
    let value = target.value;

    console.log("analyticId ", analyticId);
    console.log("value ", value);

    ApiGameAnalytics.updateAnalytic(analyticId, {
      teamNameOverride: value,
    });
  }

  async setStateIsUserTour() {
    let response = await ApiUser.getAccount();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isUserTour: response.data.id === this.state.tour?.UserId,
      }));
    }
  }

  async getTour(tourId) {
    if (!tourId || tourId === -1) {
      return;
    }
    console.log("tourId ", tourId);
    ApiTour.getTourPublic(tourId).then((tourDatas) => {
      if (!tourDatas || tourDatas.status !== 200 || !tourDatas.data) {
        return;
      }
      console.log("tourDatas", tourDatas);

      const totalZones = tourDatas.data.zones.length;
      const totalEnigmas = tourDatas.data.zones.reduce(
        (count, zone) => count + zone.enigmas.length,
        0
      );
      const totalPoints = tourDatas.data.zones.reduce(
        (count, zone) =>
          count +
          zone.enigmas.reduce(
            (countEnigma, enigma) => countEnigma + enigma.points,
            0
          ),
        0
      );

      this.setState(
        (prevState) => ({
          ...prevState,
          tour: tourDatas.data,
          totalZones: totalZones,
          totalEnigmas: totalEnigmas,
          totalPoints: totalPoints,
        }),
        () => {
          console.log("state sets");
          this.setStateIsUserTour();
          this.isLeaderboardActivated(this.state.tour.UserId);
        }
      );
    });
  }

  async isLeaderboardActivated(userId) {
    let response = await ApiUser.getUserAccount(userId);

    console.log("response.status get account ", response);

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        leaderboardActivated:
          response.data.role !== "PACK_DECOUVERTE" || this.state.isAdmin,
      }));
      console.log(
        'response.data.role !== "PACK_DECOUVERTE" ',
        response.data.role !== "PACK_DECOUVERTE"
      );
      console.log("this.state.isAdmin ", this.state.isAdmin);
    }
  }

  async getAnalytics(tourId) {
    if (!tourId || tourId === -1) {
      return;
    }
    console.log("this.state.sortColumn ", this.state.sortColumn);
    ApiGameAnalytics.getAnalytics(
      tourId,
      this.state.page,
      this.state.rowPerPage,
      this.state.sortColumn
    ).then((analytics) => {
      if (!analytics.data || analytics.status !== 200) {
        return;
      }
      console.log("analytics", analytics);

      this.setState(
        (prevState) => ({
          ...prevState,
          analytics: analytics.data.items,
          analyticsDay: analytics.data.itemsDay,
          analyticsMonth: analytics.data.itemsMonth,
          totalRows: analytics.data.total,
          totalRowsDay: analytics.data.totalDay,
          totalRowsMonth: analytics.data.totalMonth,
        }),
        () => {}
      );
    });
  }

  shouldShowTeamNameInput = () => {
    console.log(" this.state.isAdmin ", this.state.isAdmin);
    console.log(" this.state.isUserTour ", this.state.isUserTour);
    return this.state.isAdmin || this.state.isUserTour;
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      (preveState) => ({
        ...preveState,
        page: newPage,
      }),
      () => {
        this.getAnalytics(this.state.tourId);
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      (preveState) => ({
        ...preveState,
        rowPerPage: +event.target.value,
        page: 0,
      }),
      () => {
        this.getAnalytics(this.state.tourId);
      }
    );
  };

  setSortColumn = (column) => {
    this.setState(
      (preveState) => ({
        ...preveState,
        sortColumn: column,
        page: 0,
      }),
      () => {
        this.getAnalytics(this.state.tourId);
      }
    );
  };

  formatDate = (date) => {
    if (!date) {
      return "";
    }

    return moment(date).format("ddd DD/MM/YYYY");
  };

  formatDateShort = (date) => {
    if (!date) {
      return "";
    }

    return moment(date).format("DD/MM/YYYY");
  };

  formatDateLong = (date) => {
    if (!date) {
      return "";
    }

    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  formatDateHours = (date) => {
    if (!date) {
      return "";
    }

    return moment(date).format("HH:mm");
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <AnalyticsListView
          state={this.state}
          analytics={this.state.analytics}
          analyticsMonth={this.state.analyticsMonth}
          analyticsDay={this.state.analyticsDay}
          tour={this.state.tour}
          classes={classes}
          tourId={this.state.tourId}
          totalZones={this.state.totalZones}
          totalEnigmas={this.state.totalEnigmas}
          totalPoints={this.state.totalPoints}
          page={this.state.page}
          rowPerPage={this.state.rowPerPage}
          formatDate={this.formatDate}
          formatDateShort={this.formatDateShort}
          formatDateLong={this.formatDateLong}
          formatDateHours={this.formatDateHours}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          setSortColumn={this.setSortColumn}
          shouldShowTeamNameInput={this.shouldShowTeamNameInput}
          updateTeamName={this.updateTeamName}
          goFullScreen={this.goFullScreen}
          exitFullScreen={this.exitFullScreen}
          forwardedRef={this.ref}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AnalyticsListComponent);
